import { Component, OnInit } from '@angular/core';
import { Dispositivo } from 'src/app/core/models/dispositivo';
import { DispositivoService } from 'src/app/core/services/http/dispositivo.service';
import { TotemService } from 'src/app/core/services/http/totem.service';
import { ConfirmationService } from 'primeng/api';
import {MessageService} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import * as moment from 'moment';
import {SweetAlertService} from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { Totem } from 'src/app/core/models/totem';
import { SucursalService } from 'src/app/core/services/http/sucursal.service';
@Component({
  selector: 'app-dispositivo',
  templateUrl: './dispositivo.component.html',
  styleUrls: ['./dispositivo.component.scss']
})
export class DispositivoComponent implements OnInit {
  moment: any = moment;
  dispositivoDialog: boolean;
  dispositivos: Dispositivo[];
  totems: any[];
  TotemsAux: any[];
  dispositivo: Dispositivo;
  totem: Totem[];
  submitted: boolean;
  accion;
  titulo;
  totemSelect;
  sucursalSelect;
  tipos: any[];
  oficinas: any[];
  oficinaSelect;
  filtrado;
  mensaje;
  constructor(
    private DispositivoService: DispositivoService,
    private ConfirmationService: ConfirmationService,
    private MessageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private SucursalService: SucursalService,
    private TotemService: TotemService,
    private SweetAlertService: SweetAlertService) { }

  async ngOnInit() {
    this.SweetAlertService.swalLoading();
    this.oficinaSelect = "";
    this.sucursalSelect = "";
    await this.TotemService.getTotems().then(totems => this.totems = totems);
    this.TotemsAux = this.totems;
    await this.SucursalService.getSucursales().then(oficinas => this.oficinas = oficinas['data']);
    this.primengConfig.ripple = false;
    this.accion = ""; 
    this.filtrado = false
    this.SweetAlertService.swalClose();
  }

  SucursalSeleccionada(Sucursal)    
  {
      this.TotemsAux = this.totems;
      if (Sucursal != -1) {
        this.TotemsAux = this.TotemsAux.filter(function (array) {
          if (array.idOficina == Sucursal) {
            return array
          }
        });
      }
  }

  async filtrar(){
    if(this.sucursalSelect == "" && this.oficinaSelect == "")
    {
      await this.SweetAlertService.swalError("Debe seleccionar sucursal y totem");
    }
    else{
      this.SweetAlertService.swalLoading();
      this.filtrado = true;
      var filtros = {
        totem: this.oficinaSelect,
        sucursal: this.sucursalSelect  
      };
      this.SweetAlertService.swalLoading();
      this.dispositivos = await this.DispositivoService.getDispositivos(filtros).then(dispositivos => this.dispositivos = dispositivos['data']);
      this.SweetAlertService.swalClose();
    }
  }

  async openNew() {
    this.dispositivo = {};
    this.dispositivo.tipo="";
    this.dispositivo.idTotem=0;
    //this.dispositivo.idRol = 1;
    this.submitted = false;
    this.dispositivoDialog = true;
    this.accion = "Agregar";
    this.titulo = "Nuevo dispositivo";
  }

  editDispositivo(dispositivo: Dispositivo) {
    this.dispositivo = { ...dispositivo };
    this.dispositivo.fechaInstalacion = this.moment(this.dispositivo.fechaInstalacion).format('YYYY-MM-DD');
    this.dispositivoDialog = true;
    this.accion = "Actualizar";
    this.titulo = "Modificar dispositivo";
  }

  async deleteDispositivo(dispositivo: Dispositivo) {
    console.log("dispositivo: ",dispositivo);
    
    this.ConfirmationService.confirm({
      message: 'Esta seguro(a) que desea eliminar a: ' + dispositivo.nombre + " del tótem " + dispositivo.idTotem + '?',
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        var dispositivoDeleted = {
          "acc": "D",
          "idRegDispositivo": dispositivo.idRegDispositivo
        }
        var resp = await this.DispositivoService.eliminarDispotivo(dispositivoDeleted);

        if (resp['status']) {
          this.dispositivo = {};
          await this.DispositivoService.administrarDispotivo(dispositivo).then(dispositivos => this.dispositivos = dispositivos['data']);
          this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Dispositivo eliminado', life: 3000 });
        } else {
          this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'El dispositivo no se pudo eliminar', life: 3000 });
        }

      }
    });

  }

  hideDialog() {
    this.dispositivoDialog = false;
    this.submitted = false;
  }

  async saveSucursal() {
    var aux = 0;
    this.submitted = true;
    if (this.accion === "Agregar") {
      var crearSucursal = {
        "acc": "N",
        "idRegDispositivo": this.dispositivo.idRegDispositivo,
        "idTotem": this.dispositivo.idTotem,
        "tipo": this.dispositivo.tipo,
        "nombre": this.dispositivo.nombre,
        "estado": true
      }
      if (this.dispositivo.tipo && this.dispositivo.nombre && this.dispositivo.idTotem) {
        var resp = await this.DispositivoService.administrarDispotivo(crearSucursal);
        console.log("Respuesta: ", resp);
        this.dispositivoDialog = false;

        if (resp['status']) {
          this.dispositivo = {};
          this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Dispositivo agregado', life: 3000 });
        } else {
          this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'El dispositivo no se pudo agregar', life: 3000 });
        }

      }

    } else if (this.accion === "Actualizar") {
      //ACTUALIZAR
      var actualizarDispositivo = {
        "acc": "U",
        "idRegDispositivo": this.dispositivo.idRegDispositivo,
        "idTotem": this.dispositivo.idTotem,
        "tipo": this.dispositivo.tipo,
        "nombre": this.dispositivo.nombre,
        "fechaInstalacion": this.dispositivo.fechaInstalacion,
        "estado":true
      }
      if (this.dispositivo.tipo && this.dispositivo.nombre && this.dispositivo.idTotem && this.dispositivo.fechaInstalacion) {
        var resp = await this.DispositivoService.administrarDispotivo(actualizarDispositivo);
        this.dispositivoDialog = false;
        this.dispositivo = {};
        if (resp['status']) {
          this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Dispositivo actualizado', life: 3000 });
        } else {

          this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'No se pudo actualziar los datos del dispositivo', life: 4000 });
        }
      }


    }
    //ACTUALIZAR LISTA DE SUCURSALES
    var filtros = {
      totem: this.oficinaSelect,
      sucursal: this.sucursalSelect  
    };
    await this.DispositivoService.getDispositivos(filtros).then(dispositivos => this.dispositivos = dispositivos['data']);


  }

  exportExcel(filtrados: any) {
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.dispositivos;
    }

    for (let element of datos) {
      var obj = {
        'ID Dispositivo': element.idRegDispositivo,
        'Nombre': element.nombre,
        'Tipo': element.tipo,
        'Fecha de registro':this.moment(element.fechaIngreso).format('DD-MM-YYYY')
        //'Estado': element.estado,
      }
      toExport.push(obj);
    }

    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(toExport);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Dispositivos");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

}
