<app-header></app-header>
<div id="main">
    <app-sidebar></app-sidebar>
    <!--CONTENIDO-->
    <div class="text-center">
        <img width="25%" src="./assets/img/totalpack.png">
    </div>
    <div class="offset-1 col-10">
        <div class="offset-3 col-6" align="center">
            <div class="row">
                <div class="form-group col-5">
                    <div class="col-sm-12">
                        <select [(ngModel)]="sucursalSelect" class="form-control">
                            <option value="">Seleccione Sucursal</option>
                            <option *ngFor="let sucursal of oficinas" [value]="sucursal.idOficina">{{sucursal.idOficina}} - {{sucursal.nombre}}</option>
                            <option value="-1">Todos</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row" style="color: black;">
                <div class="form-group col-5">
                    <label for="staticEmail" class="col-sm-12 col-form-label label">Fecha de inicio</label>
                    <div class="col-sm-12">
                        <input type="date" class="form-control" [max]="moment().format('YYYY-MM-DD')"
                            [(ngModel)]="feInicio">
                    </div>
                </div>
                <div class="form-group col-5">
                    <label class="col-sm-12 col-form-label label">Fecha de termino</label>
                    <div class="col-sm-12">
                        <input type="date" class="form-control" [max]="moment().format('YYYY-MM-DD')"
                            [(ngModel)]="feTermino">
                    </div>
                </div>
                <div class="col-2 form-group">
                    <label class="col-sm-12 col-form-label" style="color: white;">.</label>
                    <div class="col-sm-12">
                        <button class="btn btn-primary" (click)="filtrar()">Buscar</button>
                    </div>

                </div>
            </div>
        </div>
        <!--SI NO HAY DATOS-->
        <div class="alert alert-danger" role="alert" *ngIf=" filtrado && 1>registros.length">
            No se han encontrado registros desde <strong>{{feInicio}} </strong>hasta<strong> {{feTermino}}</strong>
          </div>
        <!--TABLAAAA-->
        <p-table #dt2  *ngIf="filtrado && registros.length>0" [value]="registros" dataKey="idRegistro" [rows]="10" styleClass="p-datatable-gridlines" [showCurrentPageReport]="true"
             [paginator]="true"
            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} encontrados"
            [globalFilterFields]="['idRegistro','idMovimiento','rutusuario','rutCliente', 'montoPago', 'idPago']">
            <ng-template pTemplate="caption">
                <div class="p-d-flex">
                    <button type="button" pButton pRipple icon="pi pi-file-excel"
                        (click)="exportExcel(dt2.filteredValue)" class="p-button-success mr-2" pTooltip="XLS"
                        tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf(dt2.filteredValue)"
                        class="p-button-warning" pTooltip="PDF" tooltipPosition="bottom"></button>
                        <div class="row" style="position: relative; margin-left: 2%; font-size: 1.16em;">
                            <span *ngIf="TotalIngresor>0">
                                <b>MONTO TOTAL: </b> <label style="color: green;">{{TotalIngresor | currency: 'CLP' : 'symbol-narrow'}}</label>  CLP
                            </span>
                            <span *ngIf="TotalIngresor<0">
                                <b>MONTO TOTAL: </b> <label style="color: red;">{{TotalIngresor | currency: 'CLP' : 'symbol-narrow'}}</label>  CLP
                            </span>
                        </div>
                    <span class="p-input-icon-left p-ml-auto float-right">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar..." />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of cols" pSortableColumn="{{col.field}}">{{col.header}} <p-sortIcon
                            field="{{col.field}}"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th>
                        <p-columnFilter type="numeric" field="idRegistro"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="numeric" field="montoIngreso"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="rutusuario"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="rutCliente"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="numeric" field="montoPago"></p-columnFilter>
                    </th>
                    <!-- <th>
                        <p-columnFilter type="numeric" field="idPago"></p-columnFilter>
                    </th> -->
                    <th>
                        <p-columnFilter type="Date" field="fecha"></p-columnFilter>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-registro>
                <tr>
                    <td>{{registro.idRegistro}}</td>
                    <td *ngIf="registro.tipoMovimiento=='T'" style="color: purple;">+ {{registro.montoIngreso}}</td>
                    <td *ngIf="registro.tipoMovimiento=='C'" style="color: green">+ {{registro.montoIngreso}}</td>
                    <td *ngIf="registro.tipoMovimiento!='C' && registro.tipoMovimiento!='T'" style="color: red;">- {{registro.montoIngreso}}</td>
                    <td>{{registro.rutUsuario}}</td>
                    <td *ngIf="registro.rutCliente!==null">{{registro.rutCliente}}</td>
                    <td *ngIf="registro.rutCliente===null" style="color: red;">No tomado</td>
                    <td>{{registro.montoPago | currency:'CLP' : 'symbol-narrow'}}  CLP</td>
                    <!-- <td>{{registro.idPago}}</td> -->
                    <td>{{registro.fecha}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>