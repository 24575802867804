<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <a class="navbar-brand" href="#" [routerLink]="['/dashboard']"><img class="img-icon" src="assets/img/totalpack.png"></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarText">
    <ul class="navbar-nav mr-auto">
      <!--DASHBOARD QUITADO, SE REEPLAZA POR ESTADO DE SALUD-->
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" href="#" [routerLink]="['/dashboard']">
          Dashboard
        </a>
      </li>
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" href="#" [routerLink]="['/report']">
          Reportes
        </a>
      </li>
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" href="#" [routerLink]="['/logs']">
          Historial
        </a>
      </li>
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" href="#" [routerLink]="['/status']">
          Estado de salud
        </a>
      </li>
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" href="#" [routerLink]="['/movimiento']">
          Movimientos
        </a>
      </li>
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" href="#" [routerLink]="['/registros']">
          Registros
        </a>
      </li>
    </ul>
    <span class="navbar-text">
      <a class="nav-link" href="#" (click)="salir($event)">
        Salir
      </a>
    </span>
  </div>
</nav>