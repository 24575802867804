<app-header></app-header>
<div id="main">
    <app-sidebar></app-sidebar>
    <div class="text-center">
        <img width="25%" src="./assets/img/totalpack.png">
    </div>
    <div class="offset-1 col-10">
        <div class="offset-3 col-6" align="center">
            <div class="row">
                <div class="form-group col-5">
                    <div class="col-sm-12">
                        <select [(ngModel)]="sucursalSelect" class="form-control" (blur)="SucursalSeleccionada(sucursalSelect)">
                            <option value="">Seleccione Sucursal</option>
                            <option *ngFor="let sucursal of oficinas" [value]="sucursal.idOficina">{{sucursal.idOficina}} - {{sucursal.nombre}}</option>
                            <option value="-1">Todos</option>
                        </select>
                    </div>
                </div>
                <div class="form-group col-5">
                    <div class="col-sm-12">
                        <select [(ngModel)]="oficinaSelect" class="form-control">
                            <option value="">Seleccione Totem</option>
                            <option *ngFor="let totem of TotemsAux" [value]="totem.idTotem">{{totem.idTotem}} - {{totem.nombreTotem}}</option>
                            <option value="-1">Todos</option>
                        </select>
                    </div>
                </div>
                <button (click)="filtrar()" class="mr-5 btn btn-primary" style="position: relative; left: 850px; bottom: 55px;">Listar denominaciones</button>
            </div>
        </div>
        <p-toast></p-toast>
        <div class="card" *ngIf="sucursalSelect != 0 && oficinaSelect != 0">
            <p-toolbar styleClass="p-mb-4">
                <ng-template pTemplate="left">
                    <button pButton pRipple label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2"
                        (click)="openNew()"></button>
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton pRipple label="Exportar a excel" (click)="exportExcel(dt.filteredValue)"
                        icon="pi pi-upload" class="p-button-help"></button>
                </ng-template>
            </p-toolbar>
            <p-table #dt *ngIf="filtrado && denominaciones && denominaciones.length>=1"  [value]="denominaciones" [rows]="10" [paginator]="true"
                [globalFilterFields]="['idGaveta','nombreTotem','nombreOficina','nombreDispositivo','tipoGaveta','denominacion1','tipo','cantidad']" styleClass="p-datatable-customers p-datatable-gridlines"
                [rowHover]="true" dataKey="denominacion.idDenominacion"
                currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} encontrados"
                [showCurrentPageReport]="true">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <h5 class="p-m-0">Denominaciones</h5>
                        <div class="row" style="position: relative; left: 10px;">
                            <span *ngIf="TotalIngresor>0">
                                <b>MONTO TOTAL: </b> <label style="color: green;">{{RealizarSuma(dt.filteredValue) | currency: 'CLP' : 'symbol-narrow'}}</label>
                            </span>
                            <span *ngIf="TotalIngresor<=0">
                                <b>MONTO TOTAL: </b> <label style="color: red;">{{RealizarSuma(dt.filteredValue) | currency: 'CLP' : 'symbol-narrow'}}</label>
                            </span>
                        </div>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Buscar..." />
                        </span>
                    </div>
                    
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        
                        <th pSortableColumn="idGaveta" style="width: 250px;">ID Gaveta <p-sortIcon field="idGaveta"></p-sortIcon>
                            <p-columnFilter type="numeric" field="idGaveta"></p-columnFilter>
                        </th>
                        <th pSortableColumn="nombreTotem">Tótem <p-sortIcon field="idGaveta"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nombreOficina">Oficina <p-sortIcon field="idGaveta"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nombreDispositivo">Dispositivo <p-sortIcon field="idGaveta"></p-sortIcon>
                        </th>
                        <th pSortableColumn="tipoGaveta">Tipo Gaveta <p-sortIcon field="tipoGaveta"></p-sortIcon>
                        </th>
                        <th pSortableColumn="denominacion1">Descripción <p-sortIcon field="denominacion1"></p-sortIcon>
                        </th>
                        <th pSortableColumn="tipo">Tipo <p-sortIcon field="tipo"></p-sortIcon>
                        </th>
                        <th pSortableColumn="cantidad">Cantidad <p-sortIcon field="cantidad"></p-sortIcon>
                        </th>
                        <!-- <th pSortableColumn="estado">Status <p-sortIcon field="estado"></p-sortIcon></th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-denominacion>
                    <tr>
                        <td>{{denominacion.idGaveta}}</td>
                        <td>{{denominacion.nombretotem}}</td>
                        <td>{{denominacion.nombreOficina}}</td>
                        <td>{{denominacion.nombreDispositivo}}</td>
                        <td>{{denominacion.tipoGaveta}}</td>
                        <td>{{denominacion.denominacion1 | currency: 'CLP' : 'symbol-narrow'}}</td>
                        <td>{{denominacion.tipo}}</td>
                        <td>{{denominacion.cantidad | currency:'CLP' : 'symbol-narrow'}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        Existen {{denominaciones ? denominaciones.length : 0 }} denominaciones.
                    </div>
                </ng-template>
            </p-table>
        </div>
        <p-dialog [(visible)]="denominacionDialog" [style]="{width: '450px'}" [header]="titulo" [modal]="true"
            styleClass="p-fluid">
            <ng-template pTemplate="content">
                <div class="p-field">
                    <label for="idGaveta">ID Gaveta a la que pertence</label>
                    <select id="idGaveta" [(ngModel)]="denominacion.idGaveta" required autofocus class="form-control">
                        <option value="0">Seleccione gaveta a la que pertenece</option>
                        <option *ngFor="let gaveta of gavetas" [value]="gaveta.idGaveta">{{gaveta.idGaveta}}</option>
                    </select>
                    <small class="p-invalid" *ngIf="submitted && !denominacion.idGaveta">La gaveta para la denominación
                        es requerida</small>
                </div>
                <div class="p-field">
                    <label for="denominacion">Denominación</label>
                    <select id="denominacion" [(ngModel)]="denominacion.denominacion1" required autofocus
                        class="form-control">
                        <option value="">Seleccione denominación</option>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                        <option value="1000">1.000</option>
                        <option value="2000">2.000</option>
                        <option value="5000">5.000</option>
                        <option value="10000">10.000</option>
                        <option value="20000">20.000</option>
                    </select>
                    <small class="p-invalid" *ngIf="submitted && !denominacion.denominacion1">La denominación es
                        obligatoria</small>
                </div>
                <div class="p-field">
                    <label for="tipo">Tipo</label>
                    <select id="tipo" [(ngModel)]="denominacion.tipo" required autofocus class="form-control">
                        <option value="">Seleccione tipo de denominación</option>
                        <option value="Billete">Billete</option>
                        <option value="Moneda">Moneda</option>
                    </select>
                    <small class="p-invalid" *ngIf="submitted && !denominacion.tipo">El tipo de denominación es
                        requerido</small>
                </div>
                <div class="p-field">
                    <label for="cantidad">Cantidad</label>
                    <input type="number" pInputText id="cantidad" [(ngModel)]="denominacion.cantidad" required autofocus
                        class="form-control" />
                    <small class="p-invalid" *ngIf="submitted && !denominacion.cantidad">La cantidad es
                        requerida</small>
                </div>
                <br>
                <button class="btn btn-primary" (click)="saveDenominacion()">{{accion}}</button>

            </ng-template>

        </p-dialog>
        <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    </div>
</div>