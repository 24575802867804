import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable({
    providedIn: 'root'
})
export class GavetaService {
    headers: HttpHeaders = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
    });

    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient
    ) { }
    
    //GET GAVETAS
    async getGavetas(filtros): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_efectivo']}/Gaveta/getGavetas?sucursal=`+filtros["sucursal"]+`&totem=`+filtros["totem"]
        try {
            return await this.httpClient.get(url,{ headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    //AGREGAR Y ACTUALIZAR GAVETAS
    async administrarGaveta(gaveta){
        const API_URL = await this.configService.getConfig();
        //const url = `${API_URL['api_efectivo']}/Atencion/BuscarTotem`;
        const url=`${API_URL['api_efectivo']}/Gaveta/AdminGaveta`;
        try {
            return await this.httpClient.post(url, gaveta,{ headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    //ELIMINAR/DESHABILITAR GAVETA
    async eliminarGaveta(gaveta){
        const API_URL = await this.configService.getConfig();
        //const url = `${API_URL['api_efectivo']}/Atencion/BuscarTotem`;
        const url=`${API_URL['api_efectivo']}/Gaveta/AdminGaveta`;
        try {
            return await this.httpClient.post(url, gaveta,{ headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
 
}



