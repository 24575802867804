import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router'
import { AuthService } from 'src/app/core/services/auh/auth.service';
import { login } from '../../core/models/login';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { ValidatorsService } from 'src/app/core/services/miscellaneous/validators.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  passUsuario = new FormControl('');
  rutUsuario = new FormControl('');

  estado = true;
  rutValid;
  disabled;

  constructor(
    private AuthService: AuthService,
    private Router: Router,
    private SweetAlertService: SweetAlertService,
    private router: Router,
    private ValidatorsService: ValidatorsService
  ) { }

  ngOnInit(): void {
    this.passUsuario = new FormControl('');
    this.rutUsuario = new FormControl('');
  }

  validaRut(): void {
    this.rutValid = this.ValidatorsService.validarRut(this.rutUsuario.value.replace(/\./g, '').replace('-', ''));
    this.rutUsuario.setValue(this.ValidatorsService.formateaRut(this.rutUsuario.value));

    if (this.rutUsuario.value.length > 10 && this.rutValid) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  }

  async ValidaLogin(){
    if(this.rutUsuario.value==="" || this.passUsuario.value===""){
      await this.SweetAlertService.swalError("¡Error, Los campos no pueden quedar vacios!");
    }else{
      var login = {
        "rut": this.rutUsuario.value,
        "clave": this.passUsuario.value
      }
      let datosRetornados = await this.AuthService.login(login)
      var estadoBtn = true;
      if(datosRetornados.status == true){
        estadoBtn = false;
        sessionStorage.setItem("autenticado", "true");
        localStorage.setItem("DatosUsu",JSON.stringify(datosRetornados["data"][0]));
        this.router.navigate(['/dashboard']);
      } else if (datosRetornados.code == 404){
        await this.SweetAlertService.swalError("¡Error, Usuario o contraseña incorrectos!");
      }else{
        await this.SweetAlertService.swalError("¡Error, Por favor intente nuevamente!");
      }
    }

  }
}
