<app-header></app-header>
<div id="main">
    <app-sidebar></app-sidebar>
    <!--CONTENIDO-->
    <div class="text-center">
        <img width="25%" src="./assets/img/totalpack.png">
    </div>
    <div class="offset-3 col-6" align="center">
        <div class="row">
            <div class="form-group col-5">
                <div class="col-sm-12">
                    <select [(ngModel)]="sucursalSelect" class="form-control" (blur)="SucursalSeleccionada(sucursalSelect)">
                        <option value="">Seleccione Sucursal</option>
                        <option *ngFor="let sucursal of oficinas" [value]="sucursal.idOficina">{{sucursal.idOficina}} - {{sucursal.nombre}}</option>
                        <option value="-1">Todos</option>
                    </select>
                </div>
            </div>
            <div class="form-group col-5">
                <div class="col-sm-12">
                    <select [(ngModel)]="totemSelect" class="form-control">
                        <option value="">Seleccione Totem</option>
                        <option *ngFor="let totem of TotemsAux" [value]="totem.idTotem">{{totem.idTotem}} - {{totem.nombreTotem}}</option>
                        <option value="-1">Todos</option>
                    </select>
                </div>
            </div>
            <button (click)="filtrar()" class="mr-5 btn btn-primary">Generar cierre <strong>Z</strong></button>
        </div>
    </div>
    <!--SI NO HAY DATOS-->
    <div class="alert alert-danger" role="alert" *ngIf="filtrado && 1>cierre.length"  align="center">
        <span style="position: relative; right: 50px;">
            No se han encontrado registros
        </span>
    </div>
    <!--TABLAAAA-->
    <p-table #dt2 *ngIf="filtrado && cierre && cierre.length>0" [value]="cierre" dataKey="idMovimiento" [rows]="10"
        [showCurrentPageReport]="true" styleClass="p-datatable-customers" [paginator]="true"
        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} encontrados"
        [globalFilterFields]="['idMovimiento','idDenominacion','idCierre','rutUsuario', 'tipo', 'montoIngreso', 'fechaRegistro']">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel(dt2.filteredValue)"
                    class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf(dt2.filteredValue)"
                    class="p-button-danger" pTooltip="PDF" tooltipPosition="bottom"></button>
                <div class="row" style="position: relative; left: 700px; font-size: 26px;">
                    <span style="position: relative; left: 200px;" *ngIf="RealizarSuma(dt2.filteredValue)>0">
                        <b>MONTO TOTAL: </b> <label style="color: green;">{{RealizarSuma(dt2.filteredValue) | currency:
                            'CLP' : 'symbol-narrow'}}</label> CLP
                    </span>
                    <span *ngIf="RealizarSuma(dt2.filteredValue)<0">
                        <b>MONTO TOTAL: </b> <label style="color: red;">{{RealizarSuma(dt2.filteredValue) | currency:
                            'CLP' : 'symbol-narrow'}}</label> CLP
                    </span>
                </div>
                <span class="p-input-icon-left p-ml-auto float-right">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value, 'contains')"
                        placeholder="Buscar..." />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of cols" pSortableColumn="{{col.field}}">{{col.header}} <p-sortIcon
                        field="{{col.field}}"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th>
                    <p-columnFilter type="numeric" field="idMovimiento"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="numeric" field="idDenominacion"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="numeric" field="idCierre"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="text" field="rutUsuario"></p-columnFilter>
                </th>
                <th>
                    <!-- <p-columnFilter type="text" field="tipo"></p-columnFilter> -->
                    <p-columnFilter field="tipo" matchMode="equals" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-dropdown [ngModel]="value" [options]="tipos" (onChange)="filter($event.value)"
                                placeholder="Cualquiera" [showClear]="true">
                                <ng-template let-option pTemplate="item">
                                    <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                                </ng-template>
                            </p-dropdown>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="numeric" field="montoIngreso"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="date" field="fechaRegistro"></p-columnFilter>
                </th>

                <!-- <th>
                    <p-columnFilter field="representative" matchMode="in" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-multiSelect [ngModel]="value" [options]="representatives" placeholder="Filtrar" (onChange)="filter($event.value)" optionLabel="name">
                                <ng-template let-option pTemplate="item">
                                    <div class="p-multiselect-representative-option">
                                        <img [alt]="option.label" src="assets/showcase/images/demo/avatar/{{option.image}}" width="32" style="vertical-align: middle" />
                                        <span class="p-ml-1">{{option.name}}</span>
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </ng-template>
                    </p-columnFilter>
                </th> -->
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-cierre>
            <tr>
                <td>{{cierre.idMovimiento}}</td>
                <td>{{cierre.idDenominacion}}</td>
                <td>{{cierre.identificador}}</td>
                <td>{{cierre.rutUsuario}}</td>
                <!--TIPO-->
                <td *ngIf="cierre.tipo==='R'" style="color: red;">Retiro</td>
                <td *ngIf="cierre.tipo==='C'" style="color: green;">Carga</td>
                <td *ngIf="cierre.tipo==='E'" style="color: red;">Egresado</td>
                <td *ngIf="cierre.tipo==='I'" style="color: green;">Ingreso</td>
                <td *ngIf="cierre.tipo==='D'">Discrepancia</td>
                <td *ngIf="cierre.tipo==='string'" style="color: gray;">N/A</td>
                <!--FIN TIPO-->
                <td *ngIf="cierre.tipo==='E'" style="color: red;">- {{cierre.montoIngreso | currency:'CLP' :
                    'symbol-narrow'}} CLP</td>
                <td *ngIf="cierre.tipo==='R'" style="color: red;">- {{cierre.montoIngreso | currency:'CLP'
                    : 'symbol-narrow'}} CLP</td>
                <td *ngIf="cierre.tipo==='C'" style="color: green;">+ {{cierre.montoIngreso | currency:'CLP'
                    : 'symbol-narrow'}} CLP</td>
                <td *ngIf="cierre.tipo==='I'" style="color: green;">+ {{cierre.montoIngreso | currency:'CLP'
                    : 'symbol-narrow'}} CLP</td>
                <td *ngIf="cierre.tipo==='D'" style="color: gray;">{{cierre.montoIngreso | currency:'CLP' :
                    'symbol-narrow'}} CLP</td>
                <td>{{moment(cierre.fechaRegistro).format('DD-MM-YYYY')}}</td>

            </tr>
        </ng-template>
    </p-table>
</div>