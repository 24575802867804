import { Component, OnInit, Inject } from '@angular/core';
import * as moment from 'moment';
import { Movimiento } from 'src/app/core/models/movimiento';
import { EfectivoService } from 'src/app/core/services/http/efectivo.service';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { SucursalService } from 'src/app/core/services/http/sucursal.service';
import { TotemService } from 'src/app/core/services/http/totem.service';
import { Totem } from 'src/app/core/models/totem';

@Component({
  selector: 'app-reporte-por-fecha',
  templateUrl: './reporte-por-fecha.component.html',
  styleUrls: ['./reporte-por-fecha.component.scss'],
})
export class ReportePorFechaComponent implements OnInit {
  cierre: Movimiento[];
  //
  filtrado;
  moment: any = moment;
  movimientos: Movimiento[];
  cols: any[];
  exportColumns: any[];
  //FILTROS
  feInicio: string;
  feTermino: string;
  cierreConTotal: any;
  tipos: any[];
  wasFilter;
  TotalIngresor: number;
  oficinaSelect;
  sucursalSelect;
  totems: Totem[];
  TotemsAux: any[];
  oficinas: any[];
  mensaje;
  totemSelect: string;
  constructor(
    private EfectivoService: EfectivoService,
    private SweetAlertService: SweetAlertService,
    private SucursalService: SucursalService,
    private TotemService: TotemService
  ) {}

  async ngOnInit() {
    this.SweetAlertService.swalLoading();
    this.totemSelect = "";
    this.sucursalSelect = "";
    await this.TotemService.getTotems().then(totems => this.totems = totems);
    this.TotemsAux = this.totems;
    await this.SucursalService.getSucursales().then(oficinas => this.oficinas = oficinas['data']);
    this.feInicio = this.moment(new Date()).format('YYYY-MM-DD');
    this.feTermino = this.moment(new Date()).format('YYYY-MM-DD');
    // this.SweetAlertService.swalLoading();
    // this.cierre = await this.EfectivoService.getCierreZ().then(cierre => this.cierre = cierre['data']);
    // this.SweetAlertService.swalClose();
            //FILTROS TIPO
            this.tipos=[
              {label: 'Egresado', value:'E'},
              {label: 'Ingresado', value:'I'},
              {label: 'Discrepancia', value:'D'},
              {label: 'Carga', value:'C'},
              {label: 'Retiro', value:'R'}
            ]
            this.filtrado = false
    //COLUMNAS
    this.cols = [
      { field: 'idMovimiento', header: 'N° Movimiento' },
      { field: 'idDenominacion', header: 'N° Denominación' },
      { field: 'identificador', header: 'ID Cierre' },
      { field: 'rutUsuario', header: 'Rut Usuario' },
      { field: 'tipo', header: 'Tipo' },
      { field: 'montoIngreso', header: 'Monto Ingresado' },
      { field: 'fechaRegistro', header: 'Fecha de Registro' },
    ];

    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.SweetAlertService.swalClose();
  }

  SucursalSeleccionada(Sucursal)    
  {
      this.TotemsAux = this.totems;
      if (Sucursal != -1) {
        this.TotemsAux = this.TotemsAux.filter(function (array) {
          if (array.idOficina == Sucursal) {
            return array
          }
        });
      }
  }

  async filtrar(){
    if(this.sucursalSelect == "" || this.totemSelect == "")
    {
      await this.SweetAlertService.swalError("Debe seleccionar sucursal y totem");
    }
    else{
      this.SweetAlertService.swalLoading();
      this.filtrado = true;
      this.cierre = await this.EfectivoService.getCierreFecha(parseInt(this.totemSelect), this.sucursalSelect, this.feInicio, this.feTermino).then(cierre => this.cierre = cierre['data']);
      this.mensaje = this.cierre['message'];
      this.SweetAlertService.swalClose();
    }
  }

  RealizarSuma(DatosTabla) {
    this.TotalIngresor = 0;
    if (DatosTabla == undefined) {
      this.cierre.forEach(element => {
        if (element.tipo == "I") {
          this.TotalIngresor += element.montoIngreso;
        }
        if (element.tipo == "E") {
          this.TotalIngresor -= element.montoIngreso;
        }
      });
    }
    else{
      DatosTabla.forEach(element => {
        if (element.tipo == "I") {
          this.TotalIngresor += element.montoIngreso;
        }
        if (element.tipo == "E") {
          this.TotalIngresor -= element.montoIngreso;
        }
      });
    }

    return this.TotalIngresor;
  }

  //EXPORTAR A EXCEL
  exportExcel(filtrados: any) {
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.cierre;
    }

    for (let element of datos) {
      var tipo;
      if(element.tipo==='E'){
        tipo="Egresado"
      }else if(element.tipo==='D'){
        tipo="Discrepancia"
      }else{
        tipo="Ingresado"
      }
      var obj = {
        'idMovimiento': element.idMovimiento,
        'idDenominacion':element.idDenominacion,
        'idCierre': element.idCierre,
        'rutUsuario': element.rutUsuario,
        'tipo': tipo,
        'montoIngreso': element.montoIngreso,
        'fechaRegistro': this.moment(element.fechaRegistro).format('DD-MM-YYYY')
      };
      toExport.push(obj);
    }

    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(toExport);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, 'CierreZ');
    });
  }
  //CONVERTIR EN EXCEL
  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver) => {
      let EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }

  //exportar a PDF
  exportPdf(filtrados: any) {
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.cierre;
    }
    for (let element of datos) {
      var tipo;
      if(element.tipo==='E'){
        tipo="Egresado"
      }else if(element.tipo==='D'){
        tipo="Discrepancia"
      }else{
        tipo="Ingresado"
      }
      var obj = {
        'idMovimiento': element.idMovimiento,
        'idDenominacion':element.idDenominacion,
        'idCierre': element.idCierre,
        'rutUsuario': element.rutUsuario,
        'tipo': tipo,
        'montoIngreso': element.montoIngreso,
        'fechaRegistro': this.moment(element.fechaRegistro).format('DD-MM-YYYY')
      };
      toExport.push(obj);
    }
    var doc = new jsPDF('p', 'pt');
    doc.text('Cierre Z', 50, 30);
    autoTable(doc, { columns: this.exportColumns, body: toExport });
    doc.save('CierreZ.pdf');
  }
}
