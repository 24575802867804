<app-header></app-header>
<div id="main">
    <app-sidebar></app-sidebar>
    <!--CONTENIDO-->
    <div class="text-center">
        <img width="25%" src="./assets/img/totalpack.png">
    </div>
    <br>
    <div class="offset-3 col-6">
        <div class="row" style="position: relative; left: 30px;">
            <div class="form-group col-5" style="position: relative; left: 100px;">
                <label for="idOficina" class="col-sm-12 col-form-label label">Sucursales</label>
                <div class="col-sm-12">
                    <select [(ngModel)]="oficinaSelect" class="form-control">
                        <option value="">Seleccione Sucursal</option>
                        <option *ngFor="let oficina of oficinas" [value]="oficina.idOficina">{{oficina.idOficina}} - {{oficina.nombre}}</option>
                        <option value="-1">Todos</option>
                    </select>
                </div>
            </div>
            <div class="form-group col-5" style="position: relative; left: 100px;">
                <label for="idOficina" class="col-sm-12 col-form-label label">Ventas</label>
                <div class="col-sm-12">
                    <select [(ngModel)]="tipoVentaSelect" class="form-control">
                        <option value="">Seleccione tipo de venta</option>
                        <option value="1">Venta del Dia</option>
                        <option value="2">Venta del Mes</option>
                        <option value="3">Venta del Año</option>
                    </select>
                </div>
            </div>
            <div class="col-2 form-group" style="position: relative; left: 120px;">
                <label class="col-sm-12 col-form-label" style="color: white;">.</label>
                <div class="col-sm-12">
                    <button class="btn btn-primary" (click)="filtrarVentaDelDia()" *ngIf="tipoVentaSelect==1">Buscar</button>
                    <button class="btn btn-primary" (click)="filtrarVentaDelMes()" *ngIf="tipoVentaSelect==2">Buscar</button>
                    <button class="btn btn-primary" (click)="filtrarVentaDelAno()" *ngIf="tipoVentaSelect==3">Buscar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="offset-1 col-10" *ngIf="tipoVentaSelect!" style="text-align: center; justify-content: center; display: flex; flex-direction: column; align-items: center;">
        <h1 style="color: black; " *ngIf="tipoVentaSelect==1">VENTAS DEL DÍA</h1>
        <h1 style="color: black; " *ngIf="tipoVentaSelect==2">VENTAS DEL MES</h1>
        <h1 style="color: black; " *ngIf="tipoVentaSelect==3">VENTAS DEL AÑO</h1>
        <table style="color: black;" class="table col-4">
            <tr>
              <th>|CARGAS|</th>
              <th>|EGRESOS|</th>
              <th>|INGRESOS|</th>
              <th>|RETIROS|</th>
              <th>|TRANSBANK|</th>
              <th>|TOTAL|</th>
              <th *ngIf="tipoVentaSelect==1">|VENTA DEL DIA|</th>
            </tr>
            <tr>
              <td style="background-color: yellow;">{{sumaC | currency: 'CLP' : 'symbol-narrow'}}</td>
              <td style="background-color: yellow;">{{sumaE | currency: 'CLP' : 'symbol-narrow'}}</td>
              <td style="background-color: yellow;">{{sumaI | currency: 'CLP' : 'symbol-narrow'}}</td>
              <td style="background-color: yellow;">{{sumaR | currency: 'CLP' : 'symbol-narrow'}}</td>
              <td style="background-color: yellow;">{{sumaT | currency: 'CLP' : 'symbol-narrow'}}</td>
              <td style="background-color: yellow;" *ngIf="tipoVentaSelect==1">{{totalResumenDia | currency: 'CLP' : 'symbol-narrow'}}</td>
              <td style="background-color: yellow;" *ngIf="tipoVentaSelect==2">{{totalResumenMes | currency: 'CLP' : 'symbol-narrow'}}</td>
              <td style="background-color: yellow;" *ngIf="tipoVentaSelect==3">{{totalResumenAno | currency: 'CLP' : 'symbol-narrow'}}</td>
              <td style="background-color: yellow;" *ngIf="tipoVentaSelect==1">{{totalVentaDia | currency: 'CLP' : 'symbol-narrow'}}</td>
            </tr>
        </table>
        <br>
        <!--TABLAAAA-->
        <!-- <p-table #dt2 *ngIf="filtrado && mensaje==='Ok'" [value]="resumenDelDiaSumas" dataKey="idOficina" [rows]="10"
            [showCurrentPageReport]="true" styleClass="p-datatable-gridlines p-datatable-responsive-demo"
            [paginator]="true" currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} encontrados"
            [globalFilterFields]="['idMovimiento','idDenominacion','idCierre','rutUsuario','tipo','montoIngreso','fechaRegistro','identificador']">
            <ng-template pTemplate="caption">
                <div class="p-d-flex">
                    <button type="button" pButton pRipple icon="pi pi-file-excel"
                        (click)="exportExcel(dt2.filteredValue)" class="p-button-success mr-2" pTooltip="XLS"
                        tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf(dt2.filteredValue)"
                        class="p-button-warning" pTooltip="PDF" tooltipPosition="bottom"></button>
                    <div class="row" style="position: relative; left: 700px; font-size: 26px;">
                    </div>

                    <span class="p-input-icon-left p-ml-auto float-right">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar..." />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of cols" pSortableColumn="{{col.field}}">{{col.header}} <p-sortIcon
                            field="{{col.field}}"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th>
                        <p-columnFilter type="numeric" field="idMovimiento"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="numeric" field="idDenominacion"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="numeric" field="idCierre"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="rutUsuario"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="tipo"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="numeric" field="montoIngreso"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="Date" field="fechaRegistro"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="identificador"></p-columnFilter>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-oficina>
                <tr>
                    <td>{{oficina.idMovimiento}}</td>
                    <td>{{oficina.idDenominacion}}</td>
                    <td>{{oficina.idCierre}}</td>
                    <td>{{oficina.rutUsuario}}</td>
                    <td>{{oficina.tipo}}</td>
                    <td>{{oficina.montoIngreso | currency: 'CLP' : 'symbol-narrow'}}</td>
                    <td>{{oficina.fechaRegistro}}</td>
                    <td>{{oficina.identificador}}</td>
                </tr>
            </ng-template>
            <br>
            <h1 style="color: black;">SUMA: {{suma}}</h1>
        </p-table>

        <p-table #dt2 *ngIf="filtrado && mensaje==='OK'" [value]="resumenDelMesSumas" dataKey="idOficina" [rows]="10"
            [showCurrentPageReport]="true" styleClass="p-datatable-gridlines p-datatable-responsive-demo"
            [paginator]="true" currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} encontrados"
            [globalFilterFields]="['idMovimiento','idDenominacion','idCierre','rutUsuario','tipo','montoIngreso','fechaRegistro','identificador']">
            <ng-template pTemplate="caption">
                <div class="p-d-flex">
                    <button type="button" pButton pRipple icon="pi pi-file-excel"
                        (click)="exportExcel(dt2.filteredValue)" class="p-button-success mr-2" pTooltip="XLS"
                        tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf(dt2.filteredValue)"
                        class="p-button-warning" pTooltip="PDF" tooltipPosition="bottom"></button>
                    <div class="row" style="position: relative; left: 700px; font-size: 26px;">
                    </div>

                    <span class="p-input-icon-left p-ml-auto float-right">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar..." />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of cols" pSortableColumn="{{col.field}}">{{col.header}} <p-sortIcon
                            field="{{col.field}}"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th>
                        <p-columnFilter type="numeric" field="idMovimiento"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="numeric" field="idDenominacion"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="numeric" field="idCierre"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="rutUsuario"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="tipo"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="numeric" field="montoIngreso"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="Date" field="fechaRegistro"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="identificador"></p-columnFilter>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-oficina>
                <tr>
                    <td>{{oficina.idMovimiento}}</td>
                    <td>{{oficina.idDenominacion}}</td>
                    <td>{{oficina.idCierre}}</td>
                    <td>{{oficina.rutUsuario}}</td>
                    <td>{{oficina.tipo}}</td>
                    <td>{{oficina.montoIngreso | currency: 'CLP' : 'symbol-narrow'}}</td>
                    <td>{{oficina.fechaRegistro}}</td>
                    <td>{{oficina.identificador}}</td>
                </tr>
            </ng-template>
            <br>
            <h1 style="color: black;">SUMA: {{suma}}</h1>
        </p-table>

        <p-table #dt2 *ngIf="filtrado && mensaje==='OK'" [value]="resumenDelAnoSumas" dataKey="idOficina" [rows]="10"
            [showCurrentPageReport]="true" styleClass="p-datatable-gridlines p-datatable-responsive-demo"
            [paginator]="true" currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} encontrados"
            [globalFilterFields]="['idMovimiento','idDenominacion','idCierre','rutUsuario','tipo','montoIngreso','fechaRegistro','identificador']">
            <ng-template pTemplate="caption">
                <div class="p-d-flex">
                    <button type="button" pButton pRipple icon="pi pi-file-excel"
                        (click)="exportExcel(dt2.filteredValue)" class="p-button-success mr-2" pTooltip="XLS"
                        tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf(dt2.filteredValue)"
                        class="p-button-warning" pTooltip="PDF" tooltipPosition="bottom"></button>
                    <div class="row" style="position: relative; left: 700px; font-size: 26px;">
                    </div>
                    <span class="p-input-icon-left p-ml-auto float-right">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar..." />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of cols" pSortableColumn="{{col.field}}">{{col.header}} <p-sortIcon
                            field="{{col.field}}"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th>
                        <p-columnFilter type="numeric" field="idMovimiento"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="numeric" field="idDenominacion"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="numeric" field="idCierre"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="rutUsuario"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="tipo"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="numeric" field="montoIngreso"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="Date" field="fechaRegistro"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="identificador"></p-columnFilter>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-oficina>
                <tr>
                    <td>{{oficina.idMovimiento}}</td>
                    <td>{{oficina.idDenominacion}}</td>
                    <td>{{oficina.idCierre}}</td>
                    <td>{{oficina.rutUsuario}}</td>
                    <td>{{oficina.tipo}}</td>
                    <td>{{oficina.montoIngreso | currency: 'CLP' : 'symbol-narrow'}}</td>
                    <td>{{oficina.fechaRegistro}}</td>
                    <td>{{oficina.identificador}}</td>
                </tr>
            </ng-template>
            <br>
            <h1 style="color: black;">SUMA: {{suma}}</h1>
        </p-table> -->
        
    </div>
</div>