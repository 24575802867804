<app-header></app-header>
<div id="main">
    <app-sidebar></app-sidebar>
    <div class="text-center">
        <img width="25%" src="./assets/img/totalpack.png">
    </div>
    <div class="offset-1 col-10">
        <p-toast></p-toast>
        <div class="card">
            <p-toolbar styleClass="p-mb-4">
                <ng-template pTemplate="left">
                    <button pButton pRipple label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2"
                        (click)="openNew()"></button>
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton pRipple label="Exportar a excel" (click)="exportExcel(dt.filteredValue)"
                        icon="pi pi-upload" class="p-button-help"></button>
                </ng-template>
            </p-toolbar>

            <p-table #dt [value]="sucursales" [rows]="10" [paginator]="true"
                [globalFilterFields]="['idOficina','nombre']" [rowHover]="true" dataKey="sucursal.idOficina"
                currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} encontrados"
                [showCurrentPageReport]="true">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <h5 class="p-m-0">Sucursales</h5>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Buscar..." />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="idOficina">ID Sucursal <p-sortIcon field="idOficina"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nombre">Nombre <p-sortIcon field="nombre"></p-sortIcon>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-sucursal>
                    <tr>
                        <td>{{sucursal.idOficina}}</td>
                        <td>{{sucursal.nombre}}</td>
                        <td>
                            <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                (click)="editSucursal(sucursal)"></button>
                            <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning"
                                (click)="deleteSucursal(sucursal)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        Existen {{sucursales ? sucursales.length : 0 }} sucursales.
                    </div>
                </ng-template>
            </p-table>
        </div>
        <p-dialog [(visible)]="sucursalDialog" [style]="{width: '450px'}" [header]="titulo" [modal]="true"
            styleClass="p-fluid">
            <ng-template pTemplate="content">
                <div class="p-field">
                    <label for="nombre">Nombre</label>
                    <input type="text" pInputText id="nombre" [(ngModel)]="sucursal.nombre" required autofocus
                        class="form-control" />
                    <small class="p-invalid" *ngIf="submitted && !sucursal.nombre">El nombre de la sucursal es
                        requerido</small>
                </div>
                <div class="p-field">
                    <label for="estado">Estado</label>
                    <select id="estado" [(ngModel)]="sucursal.estado" required autofocus class="form-control">
                        <option value="true" style="color: green;">Habilitada</option>
                        <option value="false" style="color: red;">Deshabilitada</option>
                    </select>
                    <small class="p-invalid" *ngIf="submitted && !sucursal.estado">El estado de la sucursal es
                        requerido</small>
                </div>
                <br>
                <button class="btn btn-primary" (click)="saveSucursal()">{{accion}}</button>

            </ng-template>

        </p-dialog>
        <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    </div>
</div>