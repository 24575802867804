<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <img class="img-icon" src="assets/img/totalpack.png">
</nav> 
<div class="container" style="margin-top: 100px;" >
    
    <div class="overflow-auto d-flex justify-content-center align-items-center" style="color: black; position: relative; top: 160px;">
        <form class="form-horizontal" role="form" style="width: 100%;">
            <h2 class="d-flex justify-content-center">INICIAR SESION</h2>
            <br>
            <div class="d-flex justify-content-center form-group" style="position: relative; width: 500px; left: 240px;">
                <label for="rutUsuario" class="col-sm-3 control-label">*RUT</label>
                <div class="col-sm-9" style="width: 500px;">
                    <input type="text" [formControl]="rutUsuario" id="rutUsuario" placeholder="12.345.678-9" maxlength="12"
                        class="form-control" autofocus (keyup)="validaRut()">
                </div>
            </div>
            <div class="d-flex justify-content-center form-group" style="position: relative; width: 500px; left: 240px;">
                <label for="passUsuario" class="col-sm-3 control-label">*CONTRASEÑA</label>
                <div class="col-sm-9">
                    <input type="password" [formControl]="passUsuario" id="passUsuario" placeholder="*****" class="form-control"
                        autofocus>
                </div>
            </div>
            <div class="d-flex justify-content-center form-group" style="position: relative; width: 500px; left: 380px;">
                <div class="col-sm-9 col-sm-offset-3">
                    <span class="help-block">*Campos obligatorios</span>
                </div>
            </div>
            <br>
            <div class="d-flex justify-content-center" style="position: relative; width: 500px; left: 285px;">
                <button type="submit" class="btn btn-outline-primary" (click)="ValidaLogin()">Ingresar</button><br>
            </div>
        </form> <!-- /form -->
      </div> <!-- ./container -->
</div>


