import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { ConfigService } from '../../../core/services/config/config.service';
import { Usuario } from '../../models/usuario';
import * as jQuery from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {
  usuario: Usuario;
  constructor(
    private ConfigService: ConfigService
  ) { }

  swalClose(): void {
    Swal.close();
  }

  async swalSuccess(text: string) {
    await Swal.fire({
      title: text,
      confirmButtonColor: '#00AA9F',
    });
  }

  async swalInfo(message: string): Promise<boolean> {
    let response = false;
    await Swal.fire({
      imageUrl: 'assets/img/alert.png',
      text: message,
      confirmButtonColor: '#E94F12',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'btn-blue',
        content: 'text-orange-isa'
      }
    }).then((result) => {
      if (result.value) {
        response = true;
      }
    });
    return response;
  }

  async swalError(text: string): Promise<boolean> {
    let response = false;
    await Swal.fire({
      icon: 'error',
      title: '¡Error!',
      text,
      timer: 20000,
      confirmButtonColor: '#E94F12',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'btn-blue',
      }
    }).then(async (result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        //TEMPORIZADOR
      } else if (result.value) {
        response = true;
      }
    });
    return response;
  }

  swalLoading(): void {
    Swal.fire({
      html: 'Cargando' +
        '<br>Por favor espere<br>' +
        '<img src="assets/img/carga.gif" alt="Cargando"> ',
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(255,255,255,0.4)',
      allowOutsideClick: false,
      customClass: {

      }
    });
  }

  async agregarUsuario(roles=[]): Promise<Usuario> {
    var usuario
    const html =
      '<div class="row">' +
        '<div class="form-group col-12">' +
          '<label class="col-sm-12 col-form-label">' +
            "RUT" +
          '</label>' +
          '<div class="col-sm-12">' +
            '<input type="text" id="rut" class="form-control">' +
          '</div>'+
        '</div>'+
        
        '<div class="form-group col-12">' +
          '<label class="col-sm-12 col-form-label">' +
            "Rol" +
          '</label>' +
          '<div class="col-sm-12">' +
            '<select class="form-control" id="rol">'+
              '<option>Seleccion un rol</option>'+
            '</select>' +
          '</div>'+
        '</div>'+
      '</div>'+
      '<div class="row">' +
        '<div class="form-group col-12">' +
          '<label class="col-sm-12 col-form-label">' +
            "Nombre" +
          '</label>' +
          '<div class="col-sm-12">' +
            '<input type="text" id="nombre" class="form-control">' +
          '</div>'+
        '</div>'+
        '<div class="form-group col-12">' +
          '<label class="col-sm-12 col-form-label">' +
            "Apellido" +
          '</label>' +
          '<div class="col-sm-12">' +
            '<input type="text" id="apellido" class="form-control">' +
          '</div>'+
        '</div>'+
      '</div>';
    await Swal.fire({
      html: html,
      showCancelButton: true,
      allowOutsideClick: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Agregar',
      confirmButtonColor: 'green',
      cancelButtonColor: 'red'
    }).then((result) => {
      usuario = {
        "rut":(<HTMLInputElement>document.getElementById('rut')).value,
        "idRol":parseInt((<HTMLInputElement>document.getElementById('rol')).value),
        "nombre":(<HTMLInputElement>document.getElementById('nombre')).value,
        "apellido":(<HTMLInputElement>document.getElementById('apellido')).value,
        "clave":(<HTMLInputElement>document.getElementById('nombre')).value,
        "estado":0
      }
      if (result.isConfirmed) {

      }
    });
    return usuario;
  }

  delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
