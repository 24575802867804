<app-header></app-header>
<div id="main">
    <app-sidebar></app-sidebar>
    <!--CONTENIDO-->
    <div class="text-center">
        <img width="25%" src="./assets/img/totalpack.png">
    </div>
    <div class="offset-1 col-10">
    <div class="offset-1" align="center" style="width: 80%;">
        
        <button href="#" [routerLink]="['/cierreZ']" class="mr-5 btn btn-primary">Generar cierre <strong>Z</strong></button>
        <button href="#" [routerLink]="['/cierreX']" class="mr-5 btn btn-primary">Generar cierre <strong>X</strong></button>
        <button href="#" [routerLink]="['/cierrePorFecha']" class="btn btn-primary">Reporte Z por fecha</button>
    </div>
    <div class="row">
        <div class="offset-1 col-10">
            <app-cierre-z *ngIf="isCierreZ"></app-cierre-z>
            <app-cierre-x *ngIf="isCierreX"></app-cierre-x>
            <app-reporte-por-fecha *ngIf="isCierreFecha"></app-reporte-por-fecha>
        </div>
    </div>

</div>