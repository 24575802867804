import { Component, OnInit, Inject } from '@angular/core';
import * as moment from 'moment';
import { TotemService } from 'src/app/core/services/http/totem.service';
import { Movimiento } from 'src/app/core/models/movimiento';
import { EfectivoService } from 'src/app/core/services/http/efectivo.service';
import { DenominacionService } from 'src/app/core/services/http/denominacion.service';
import {Oficina} from 'src/app/core/models/oficina';
//PDF
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
//
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  uploadingFile: boolean;
  cargarArchivo:boolean=false;
  moment: any = moment;
  totems: any[];
  oficinas: Oficina[];
  denominaciones: any[];
  //
  filtrado;
  movimientos: Movimiento[];
  cols: any[];
  idOficina;
  exportColumns: any[];
  //FILTROS
  feInicio: string;
  feTermino: string;
  oficinaSelect;
  tipoVentaSelect;
  idDenominacion;
  tipoMovimiento;
  fechaRegistro;
  TotalIngresor=0;
  oficinasSelect;
  tipos: any[];
  idtotem: string;
  mensaje: any;
  respuesta: any[];
  suma: any;
  sumaI;
  sumaC;
  sumaE;
  sumaR;
  sumaT;
  total;
  resumenDelDiaSumas;
  resumenDelMesSumas;
  resumenDelAnoSumas;
  dataResumen: any[];
  totalResumenDia: any;
  totalResumenMes: any;
  totalResumenAno: any;
  totalVentaDia: any;
  totalVentaMes: any;
  totalVentaAno: any;

  constructor(
    private SweetAlertService: SweetAlertService,
    private TotemService: TotemService,
    private EfectivoService: EfectivoService,
  ) { }

  async ngOnInit(){
    this.SweetAlertService.swalLoading();
    this.oficinaSelect = "";
    this.tipoVentaSelect = "";
    await this.EfectivoService.getOficinas().then((oficinas) => (this.oficinas = oficinas['data']));
    this.feInicio = this.moment(new Date()).format('YYYY-MM-DD')
    this.feTermino = this.moment(new Date()).format('YYYY-MM-DD')
    this.cols = [
      { field: 'idMovimiento', header: 'ID Movimiento' },
      { field: 'idDenominacion', header: 'ID Denominación' },
      { field: 'idCierre', header: 'ID Cierre' },
      { field: 'rutUsuario', header: 'Rut Usuario' },
      { field: 'tipo', header: 'Tipo' },
      { field: 'montoIngreso', header: 'Monto Ingreso' },
      { field: 'fechaRegistro', header: 'Fecha de Registro' },
      { field: 'identificador', header: 'Identificador' }
    ];
    this.SweetAlertService.swalClose();
  }

  async filtrarVentaDelDia() {
    this.filtrado = false;
    this.tipoVentaSelect = 1;
    if (this.moment(this.feInicio).isAfter(this.moment(this.feTermino))) {
      this.SweetAlertService.swalError('Seleccione un rango de fechas valido');
    } else if (this.idtotem === '') {
      this.SweetAlertService.swalError('Seleccione una oficina');
    } else {
      this.filtrado = true;
      var filtros = {
        IdOficina: this.oficinaSelect
      };
      this.SweetAlertService.swalLoading();
      var ventaDelDia = await this.EfectivoService.getVentaDelDia(filtros);
      await this.EfectivoService.getResumenDelDia(filtros).then((oficinas) => (this.resumenDelDiaSumas = oficinas));
      this.SweetAlertService.swalClose();
      this.totalVentaDia = ventaDelDia['suma'];
      this.dataResumen = this.resumenDelDiaSumas['data']
      this.sumaI = this.resumenDelDiaSumas['sumaI'];
      this.sumaC = this.resumenDelDiaSumas['sumaC'];
      this.sumaE = this.resumenDelDiaSumas['sumaE'];
      this.sumaR = this.resumenDelDiaSumas['sumaR'];
      this.sumaT = this.resumenDelDiaSumas['sumaT'];
      this.totalResumenDia = this.resumenDelDiaSumas['total'];
      var resumenDelDia = await this.EfectivoService.getResumenDelDia(filtros);
      this.mensaje = ventaDelDia['message'];
      this.suma = ventaDelDia['suma']
      console.log("VENTA DEL DIA ", ventaDelDia);
      console.log("RESUMEN DEL DIA CON DATOS", this.resumenDelDiaSumas);
      console.log("RESUMEN DEL DIA", resumenDelDia);
    }
  }

  async filtrarVentaDelMes() {
    this.tipoVentaSelect=2;
    this.filtrado = false;
    if (this.moment(this.feInicio).isAfter(this.moment(this.feTermino))) {
      this.SweetAlertService.swalError('Seleccione un rango de fechas valido');
    } else if (this.idtotem === '') {
      this.SweetAlertService.swalError('Seleccione una oficina');
    } else {
      this.filtrado = true;
      var filtros = {
        IdOficina: this.oficinaSelect
      };
      this.SweetAlertService.swalLoading();
      var ventaDelMes = await this.EfectivoService.getResumenDelMes(filtros);
      await this.EfectivoService.getResumenDelMes(filtros).then((oficinas) => (this.resumenDelMesSumas = oficinas));
      this.SweetAlertService.swalClose();
      this.totalVentaMes = ventaDelMes['total'];
      this.dataResumen = this.resumenDelMesSumas['data']
      this.sumaI = this.resumenDelMesSumas['sumaI'];
      this.sumaC = this.resumenDelMesSumas['sumaC'];
      this.sumaE = this.resumenDelMesSumas['sumaE'];
      this.sumaR = this.resumenDelMesSumas['sumaR'];
      this.sumaT = this.resumenDelMesSumas['sumaT'];
      this.totalResumenMes = this.resumenDelMesSumas['total'];
      var resumenDelMes = await this.EfectivoService.getResumenDelMes(filtros);
      this.mensaje = ventaDelMes['message'];
      this.suma = ventaDelMes['total']
      console.log("VENTA DEL MES ", ventaDelMes);
      console.log("RESUMEN DEL MES CON DATOS", this.resumenDelMesSumas);
      console.log("RESUMEN DEL MES", resumenDelMes);
    }
  }

  async filtrarVentaDelAno() {
    this.tipoVentaSelect=3;
    this.filtrado = false;
    if (this.moment(this.feInicio).isAfter(this.moment(this.feTermino))) {
      this.SweetAlertService.swalError('Seleccione un rango de fechas valido');
    } else if (this.idtotem === '') {
      this.SweetAlertService.swalError('Seleccione una oficina');
    } else {
      this.filtrado = true;
      var filtros = {
        IdOficina: this.oficinaSelect
      };
      this.SweetAlertService.swalLoading();
      var ventaDelAno = await this.EfectivoService.getResumenDelAno(filtros);
      await this.EfectivoService.getResumenDelAno(filtros).then((oficinas) => (this.resumenDelAnoSumas = oficinas));
      this.SweetAlertService.swalClose();
      this.totalVentaAno = ventaDelAno['total'];
      this.dataResumen = this.resumenDelAnoSumas['data']
      this.sumaI = this.resumenDelAnoSumas['sumaI'];
      this.sumaC = this.resumenDelAnoSumas['sumaC'];
      this.sumaE = this.resumenDelAnoSumas['sumaE'];
      this.sumaR = this.resumenDelAnoSumas['sumaR'];
      this.sumaT = this.resumenDelAnoSumas['sumaT'];
      this.totalResumenAno = this.resumenDelAnoSumas['total'];
      var ResumenDelAno = await this.EfectivoService.getResumenDelAno(filtros);
      this.mensaje = ventaDelAno['message'];
      this.suma = ventaDelAno['total']
      console.log("VENTA DEL DIA ", ventaDelAno);
      console.log("RESUMEN DEL DIA CON DATOS", this.resumenDelDiaSumas);
      console.log("RESUMEN DEL DIA", ResumenDelAno);
    }
  }

  //EXPORTAR A EXCEL
  exportExcel(filtrados: any) {
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.oficinas;
    }

    for (let element of datos) {
      var obj = {
        'N° Registro': element.idHistorial,
        'N° Codigo': element.idCodigo,
        Fecha: this.moment(element.fecha).format('DD-MM-YYYY'),
        Hora: this.moment(element.fecha).format('HH:mm:ss'),
        Mensaje: element.info,
        'N° Tótem': element.idTotem,
        Resultado: element.resultado,
      };
      toExport.push(obj);
    }

    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(toExport);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, 'Historial');
    });
  }
  //CONVERTIR EN EXCEL
  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver) => {
      let EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }

  //exportar a PDF
  exportPdf(filtrados: any) {
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.oficinas;
    }
    for (let element of datos) {
      var obj = {
        idHistorial: element.idHistorial,
        idCodigo: element.idCodigo,
        fecha: this.moment(element.fecha).format('DD-MM-YYYY'),
        hora: this.moment(element.fecha).format('HH:mm:ss'),
        info: element.info,
        idTotem: element.idTotem,
        resultado: element.resultado,
      };
      toExport.push(obj);
    }
    var doc = new jsPDF('p', 'pt');
    doc.text('Historial de uso', 50, 30);
    autoTable(doc, { columns: this.exportColumns, body: toExport });
    doc.save('Historial.pdf');
  }

}
