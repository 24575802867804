import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable({
    providedIn: 'root'
})
export class SucursalService {
    headers: HttpHeaders = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
    });

    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient
    ) { }
    //GET TOTEMS
    async getSucursales(): Promise<any> {
        const API_URL = await this.configService.getConfig();
        //const url = `${API_URL['api_efectivo']}/Atencion/BuscarTotem`;
        const url=`${API_URL['api_efectivo']}/Oficina/getOficina`;
        try {
            return await this.httpClient.get(url,{ headers: this.headers }).toPromise();
            // return {
            //     status: true,
            //     code: 200,
            //     data: [
            //         { "rut": 1, "idRol":"1", "nombre": "omar", "apellido":"mora" },
            //         { "rut": 2, "idRol":"2", "nombre": "omar", "apellido":"mora" },
            //         { "rut": 3, "idRol":"3", "nombre": "omar", "apellido":"mora" },
            //         { "rut": 4, "idRol":"4",  "nombre": "omar", "apellido":"mora" },
            //         { "rut": 5, "idRol":"5",  "nombre": "omar", "apellido":"mora" }
            //     ]
            // }
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    async deleteSucursal(sucursal): Promise<any> {
        const API_URL = await this.configService.getConfig();
        //const url = `${API_URL['api_efectivo']}/Atencion/BuscarTotem`;
        const url=`${API_URL['api_efectivo']}/Oficina/AdminOficina`;
        try {
            return await this.httpClient.post(url, sucursal,{ headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async agregarSucursal(sucursal): Promise<any> {
        const API_URL = await this.configService.getConfig();
        //const url = `${API_URL['api_efectivo']}/Atencion/BuscarTotem`;
        const url=`${API_URL['api_efectivo']}/Oficina/AdminOficina`;
        try {
            return await this.httpClient.post(url, sucursal,{ headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    
}