<app-header></app-header>
<div id="main">
    <app-sidebar></app-sidebar>
    <div class="text-center">
        <img width="25%" src="./assets/img/totalpack.png">
    </div>
    <div class="offset-1 col-10">
        <div class="offset-3 col-6" align="center">
            <div class="row">
                <div class="form-group col-5">
                    <div class="col-sm-12">
                        <select [(ngModel)]="sucursalSelect" class="form-control" (blur)="SucursalSeleccionada(sucursalSelect)">
                            <option value="">Seleccione Sucursal</option>
                            <option *ngFor="let sucursal of oficinas" [value]="sucursal.idOficina">{{sucursal.idOficina}} - {{sucursal.nombre}}</option>
                            <option value="-1">Todos</option>
                        </select>
                    </div>
                </div>
                <div class="form-group col-5">
                    <div class="col-sm-12">
                        <select [(ngModel)]="oficinaSelect" class="form-control">
                            <option value="">Seleccione Totem</option>
                            <option *ngFor="let totem of TotemsAux" [value]="totem.idTotem">{{totem.idTotem}} - {{totem.nombreTotem}}</option>
                            <option value="-1">Todos</option>
                        </select>
                    </div>
                </div>
                <button (click)="filtrar()" class="mr-5 btn btn-primary" style="position: relative; left: 850px; bottom: 55px;">Listar dispositivos</button>
            </div>
        </div>
        <p-toast></p-toast>
        <div class="card">
            <p-toolbar styleClass="p-mb-4" *ngIf="sucursalSelect != 0 && oficinaSelect != 0">
                <ng-template pTemplate="left">
                    <button pButton pRipple label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2"
                        (click)="openNew()"></button>
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton pRipple label="Exportar a excel" (click)="exportExcel(dt.filteredValue)"
                        icon="pi pi-upload" class="p-button-help"></button>
                </ng-template>
            </p-toolbar>
            <p-table #dt *ngIf="filtrado && dispositivos && dispositivos.length>=1" [value]="dispositivos" [rows]="10" [paginator]="true"
                [globalFilterFields]="['idRegDispositivo','idTotem','nombreTotem','tipo','nombre', 'fechaIngreso', 'fechaInstalacion' ]"
                [rowHover]="true" dataKey="dispositivo.IdRegDispositivo"
                currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} encontrados"
                [showCurrentPageReport]="true">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <h5 class="p-m-0">Dispositivos</h5>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Buscar..." />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="idRegDispositivo">ID Dispositivo <p-sortIcon field="idRegDispositivo">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="nombreTotem">Tótem <p-sortIcon field="nombreTotem"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nombreOficina">Sucursal <p-sortIcon field="nombreOficina"></p-sortIcon>
                        </th>
                        <th pSortableColumn="tipo">Tipo <p-sortIcon field="tipo"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nombre">Nombre <p-sortIcon field="nombre"></p-sortIcon>
                        </th>
                        <th pSortableColumn="fechaIngreso">Fecha de registro <p-sortIcon field="fechaIngreso">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="fechaInstalacion">Fecha de instalación <p-sortIcon
                                field="fechaInstalacion"></p-sortIcon>
                        </th>
                        <!-- <th pSortableColumn="estado">Status <p-sortIcon field="estado"></p-sortIcon></th> -->
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-dispositivo>
                    <tr>
                        <td>{{dispositivo.idRegDispositivo}}</td>
                        <td>{{dispositivo.idTotem}} - {{dispositivo.nombreTotem}} - {{dispositivo.nombreOficina}}</td>
                        <td>{{dispositivo.nombreOficina}}</td>
                        <td>{{dispositivo.tipo}}</td>
                        <td>{{dispositivo.nombre}}</td>
                        <td>{{moment(dispositivo.fechaIngreso).format('DD-MM-YYYY')}}</td>
                        <td *ngIf="dispositivo.fechaInstalacion!==null">{{moment(dispositivo.fechaInstalacion).format('DD-MM-YYYY')}}</td>
                        <td *ngIf="dispositivo.fechaInstalacion===null" style="color: red;">Sin instalación</td>
                        <!-- <td>{{usuario.estado}}</td> -->
                        <td>
                            <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                (click)="editDispositivo(dispositivo)"></button>
                            <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning"
                                (click)="deleteDispositivo(dispositivo)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        Existen {{dispositivos ? dispositivos.length : 0 }} dispositivos.
                    </div>
                </ng-template>
            </p-table>
        </div>
        <p-dialog [(visible)]="dispositivoDialog" [style]="{width: '450px'}" [header]="titulo" [modal]="true"
            styleClass="p-fluid">
            <ng-template pTemplate="content">
                <div class="p-field" *ngIf="accion==='Agregar'">
                    <label for="idRegDispositivo">N° Serial de dispositivo</label>
                    <input type="number" pInputText id="idRegDispositivo" [(ngModel)]="dispositivo.idRegDispositivo"
                        required autofocus class="form-control" />
                    <small class="p-invalid" *ngIf="submitted && !dispositivo.idRegDispositivo">El numero de serie del
                        dispositivo es requerido</small>
                </div>
                <div class="p-field">
                    <label for="idTotem">Tótem al que pertenece</label>
                    <select id="idTotem" [(ngModel)]="dispositivo.idTotem" required autofocus class="form-control">
                        <option value="0">Seleccione tótem</option>
                        <option *ngFor="let totem of totems" [value]="totem.idTotem">{{totem.idTotem}} - {{totem.nombreTotem}}</option>
                    </select>
                    <small class="p-invalid" *ngIf="submitted && !dispositivo.idTotem">El tótem es requerido</small>
                </div>
                <div class="p-field">
                    <label for="tipo">Tipo de dispositivo</label>
                    <select id="tipo" [(ngModel)]="dispositivo.tipo" required autofocus class="form-control">
                        <option value="">Seleccione tipo de dispositivo</option>
                        <option value="Moneda">Moneda</option>
                        <option value="Billete">Billete</option>
                        <option value="G13">G13</option>
                    </select>
                    <small class="p-invalid" *ngIf="submitted && !dispositivo.tipo">El tipo de dispositivo es
                        requerido</small>
                </div>
                <div class="p-field">
                    <label for="nombre">Nombre</label>
                    <input type="text" pInputText id="nombre" [(ngModel)]="dispositivo.nombre" required autofocus
                        class="form-control" />
                    <small class="p-invalid" *ngIf="submitted && !dispositivo.nombre">El nombre de dispositivo es
                        requerido</small>
                </div>
                <div class="p-field" *ngIf="accion==='Actualizar'">
                    <label for="fechaInstalacion">Fecha de instalación</label>
                    <input type="date" pInputText id="fechaInstalacion" [(ngModel)]="dispositivo.fechaInstalacion"
                        [max]="moment().format('YYYY-MM-DD')" required autofocus class="form-control" />
                    <small class="p-invalid" *ngIf="submitted && !dispositivo.fechaInstalacion">La fecha de instalación
                        del dispositivo es requerida</small>
                </div>
                <br>
                <button class="btn btn-primary" (click)="saveSucursal()">{{accion}}</button>

            </ng-template>

        </p-dialog>
        <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    </div>
</div>