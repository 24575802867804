<app-header></app-header>
<div id="main">
    <app-sidebar></app-sidebar>
    <!--CONTENIDO-->
    <div class="text-center">
        <img width="25%" src="./assets/img/totalpack.png">
    </div>
    <div class="offset-1 col-10">
        <div class="offset-3 col-6" align="center">
            <div class="row">
                <div class="form-group col-5">
                    <div class="col-sm-12">
                        <select [(ngModel)]="codigoSelect" class="form-control">
                            <option value="">Seleccione Código</option>
                            <option *ngFor="let codigo of codigos" [value]="codigo.idCodigo">{{codigo.idCodigo}} - {{codigo.descripcion}}</option>
                            <option value="-1">Todos</option>
                        </select>
                    </div>
                </div>
                <div class="form-group col-5">
                    <div class="col-sm-12">
                        <select [(ngModel)]="totemSelect" class="form-control">
                            <option value="">Seleccione Totem</option>
                            <option *ngFor="let totem of totems" [value]="totem.idTotem">{{totem.idTotem}} - {{totem.nombreTotem}}</option>
                            <option value="-1">Todos</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row" style="color: black;">
                <div class="form-group col-5">
                    <label for="staticEmail" class="col-sm-12 col-form-label label">Fecha de inicio</label>
                    <div class="col-sm-12">
                        <input type="date" class="form-control" [max]="moment().format('YYYY-MM-DD')"
                            [(ngModel)]="feInicio">
                    </div>
                </div>
                <div class="form-group col-5">
                    <label class="col-sm-12 col-form-label label">Fecha de termino</label>
                    <div class="col-sm-12">
                        <input type="date" class="form-control" [max]="moment().format('YYYY-MM-DD')"
                            [(ngModel)]="feTermino">
                    </div>
                </div>
                <div class="col-2 form-group">
                    <label class="col-sm-12 col-form-label" style="color: white;">.</label>
                    <div class="col-sm-12">
                        <button class="btn btn-primary" (click)="filtrar()">Buscar</button>
                    </div>
        
                </div>
            </div>
        </div>
        <!--SI NO HAY DATOS-->
        <div class="alert alert-danger" role="alert" *ngIf=" filtrado && !historiales">
            No se han encontrado registros desde <strong>{{feInicio}} </strong>hasta<strong> {{feTermino}}</strong>
          </div>
        <!--TABLAAAA-->
        <p-table #dt2  *ngIf="filtrado && historiales" [value]="historiales" dataKey="idHistorial" [rows]="10" [showCurrentPageReport]="true"
            styleClass="p-datatable-gridlines" [paginator]="true"
            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} encontrados"
            [globalFilterFields]="['idHistorial','idCodigo','fecha','resultado', 'info', 'idTotem', 'valor']">
            <ng-template pTemplate="caption">
                <div class="p-d-flex">
                    <button type="button" pButton pRipple icon="pi pi-file-excel"
                        (click)="exportExcel(dt2.filteredValue)" class="p-button-success mr-2" pTooltip="XLS"
                        tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf(dt2.filteredValue)"
                        class="p-button-warning" pTooltip="PDF" tooltipPosition="bottom"></button>
                    <span class="p-input-icon-left p-ml-auto float-right">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar..." />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of cols" pSortableColumn="{{col.field}}">{{col.header}} <p-sortIcon
                            field="{{col.field}}"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th>
                        <p-columnFilter type="numeric" field="idHistorial"></p-columnFilter>
                    </th>
                    <th><p-columnFilter type="numeric" field="idCodigo"></p-columnFilter></th>
                    <th >
                        <p-columnFilter type="text" field="fecha"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="valor"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="info"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="numeric" field="idTotem"></p-columnFilter>
                    </th>
                    <th>
                        <!-- <p-columnFilter type="text" field="resultado"></p-columnFilter> -->
                        <p-columnFilter field="resultado" matchMode="equals" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown [ngModel]="value" [options]="resultados" (onChange)="filter($event.value)"
                                    placeholder="Todos" [showClear]="true">
                                    <ng-template let-option pTemplate="item">
                                        <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                                    </ng-template>
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </th>

                    <!-- <th>
                    <p-columnFilter field="representative" matchMode="in" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-multiSelect [ngModel]="value" [options]="representatives" placeholder="Filtrar" (onChange)="filter($event.value)" optionLabel="name">
                                <ng-template let-option pTemplate="item">
                                    <div class="p-multiselect-representative-option">
                                        <img [alt]="option.label" src="assets/showcase/images/demo/avatar/{{option.image}}" width="32" style="vertical-align: middle" />
                                        <span class="p-ml-1">{{option.name}}</span>
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </ng-template>
                    </p-columnFilter>
                </th> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-historial>
                <tr>
                    <td>{{historial.idHistorial}}</td>
                    <td>{{historial.idCodigo}}</td>
                    <td>{{moment(historial.fecha).format('DD-MM-YYYY HH:mm:ss')}}</td>
                    <td>{{historial.valor}}</td>
                    <td>{{historial.info}}</td>
                    <td>{{historial.idTotem}}</td>
                    <td [ngClass]="{'success':historial.resultado==='Exitoso', 'failed':historial.resultado!=='Exitoso'}">{{historial.resultado}}</td>

                </tr>
            </ng-template>
        </p-table>
    </div>
</div>