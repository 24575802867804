import { Component, OnInit, Inject } from '@angular/core';
import * as moment from 'moment';
import {Movimiento} from 'src/app/core/models/movimiento';
import { EfectivoService } from 'src/app/core/services/http/efectivo.service';
import {jsPDF} from 'jspdf';
import autoTable from 'jspdf-autotable'
import {SweetAlertService} from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { SucursalService } from 'src/app/core/services/http/sucursal.service';
import { TotemService } from 'src/app/core/services/http/totem.service';
import { Totem } from 'src/app/core/models/totem';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  isCierreZ;
  isCierreX;
  isCierreFecha;
  cierre: Movimiento[];
  //
  filtrado;
  moment: any = moment;
  movimientos: Movimiento[];
  cols: any[];
  exportColumns: any[];
  //FILTROS
  feInicio: string;
  feTermino: string;
  cierreConTotal: any;
  TotalIngresor: number;
  totems: Totem[];
  oficinas: any[];
  totemSelect;
  sucursalSelect;
  constructor(    
    private EfectivoService: EfectivoService, 
    private SweetAlertService: SweetAlertService,
    private SucursalService: SucursalService,
    private TotemService: TotemService
    ) { }

  async ngOnInit() {
    this.totemSelect = "";
    this.sucursalSelect = "";
    await this.TotemService.getTotems().then(totems => this.totems = totems);
    await this.SucursalService.getSucursales().then(oficinas => this.oficinas = oficinas['data']);
    this.feInicio = this.moment(new Date()).format('YYYY-MM-DD');
    this.feTermino = this.moment(new Date()).format('YYYY-MM-DD');
    //OBTENER DENOMINACIONES
    this.filtrado = false
    // this.SweetAlertService.swalLoading();
    // this.cierre = await this.EfectivoService.getCierreZ().then(cierre => this.cierre = cierre['data']);
    // this.SweetAlertService.swalClose();
    //COLUMNAS
    this.cols = [
      { field: 'idMovimiento', header: 'N° Movimiento' },
      { field: 'idDenominacion', header: 'N° Denominación' },
      { field: 'idCierre', header: 'N° Cierre' },
      { field: 'rutUsuario', header: 'Rut usuario' },
      { field: 'tipo', header: 'Tipo' },
      { field: 'montoIngreso', header: 'Monto ingresado' },
      { field: 'fechaRegistro', header: 'Fecha de registro' }
  ];

  this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));

  }
  RealizarSuma(DatosTabla) {
    this.TotalIngresor = 0;
    if (DatosTabla == undefined) {
      this.cierre.forEach(element => {
        if (element.tipo == "I") {
          this.TotalIngresor += element.montoIngreso;
        }
        if (element.tipo == "E") {
          this.TotalIngresor -= element.montoIngreso;
        }
      });
    }
    else{
      DatosTabla.forEach(element => {
        if (element.tipo == "I") {
          this.TotalIngresor += element.montoIngreso;
        }
        if (element.tipo == "E") {
          this.TotalIngresor -= element.montoIngreso;
        }
      });
    }

    return this.TotalIngresor;
  }

 

  //EXPORTAR A EXCEL
    exportExcel(filtrados: any) {
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.cierre;
    }

    for (let element of datos) {
      var obj = {
        'N° Movimiento': element.idMovimiento,
        'N° Denominación':element.idDenominacion,
        'N° Cierre': element.idCierre,
        'Rut usuario': element.rutUsuario,
        'Tipo': element.tipo,
        'Monto': element.montoIngreso,
        'Fecha de registro': this.moment(element.fechaRegistro).format('DD-MM-YYYY')
      }
      toExport.push(obj);
    }

      import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(toExport);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, "CierreX");
      });
  }
  //CONVERTIR EN EXCEL
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  //exportar a PDF
  exportPdf(filtrados: any) {
    
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.cierre;
    }
    for (let element of datos) {
      var obj = {
        'idMovimiento': element.idMovimiento,
        'idDenominacion':element.idDenominacion,
        'idCierre': element.idCierre,
        'rutUsuario': element.rutUsuario,
        'tipo': element.tipo,
        'montoIngreso': element.montoIngreso,
        'fechaRegistro': this.moment(element.fechaRegistro).format('DD-MM-YYYY')
      }
      toExport.push(obj);
    }
     var doc = new jsPDF('p', 'pt');
     doc.text("Cierre X",50, 30);
      autoTable(doc, {columns: this.exportColumns,body: toExport})
      doc.save('CierreX.pdf');
  }

  tipoCierre(cierre: any){
      if(cierre==='cierreZ'){
        this.isCierreZ=true
        this.isCierreX=false;
        this.isCierreFecha=false;
      }else if(cierre==='cierreX'){
        this.isCierreX=true;
        this.isCierreZ=false;
        this.isCierreFecha=false;
      }else if(cierre==='cierreFecha'){
        this.isCierreFecha=true;
        this.isCierreZ=false
        this.isCierreX=false;
      }
  }
}
