import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { EfectivoService } from 'src/app/core/services/http/efectivo.service';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { Historial } from 'src/app/core/models/historial';
import { TotemService } from 'src/app/core/services/http/totem.service';
//PDF
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
})
export class LogsComponent implements OnInit {
  totems: any[];
  codigos: any[];
  //
  filtrado;
  moment: any = moment;
  historiales: Historial[];
  cols: any[];
  exportColumns: any[];
  //FILTROS
  feInicio: string;
  feTermino: string;
  resultado;
  idtotem;
  totemSelect;
  codigoSelect;
  resultados: any[];
  idCodigo;

  constructor(
    private EfectivoService: EfectivoService,
    private SweetAlertService: SweetAlertService,
    private TotemService: TotemService
  ) {}

  async ngOnInit() {
    //OBTENER TOTEMS
    this.SweetAlertService.swalLoading();
    this.codigoSelect = "";
    this.totemSelect = "";
    this.codigos = await this.EfectivoService.getCodigo().then((codigos) => (this.codigos = codigos['data']));
    this.totems = await this.TotemService.getTotems().then(totems => this.totems = totems);
    console.log("CODIGOS: ", this.codigos);
    console.log("TOTEMS: ",this.totems);
    //INICIALIZACION INPUTS
    this.feInicio = this.moment(new Date()).format('YYYY-MM-DD');
    this.feTermino = this.moment(new Date()).format('YYYY-MM-DD');
    this.resultado = '';
    this.idtotem = '';
    //COLUMNAS
    this.cols = [
      { field: 'idHistorial', header: 'N° Registro' },
      { field: 'idCodigo', header: 'N° Codigo' },
      { field: 'fecha', header: 'Fecha' },
      { field: 'valor', header: 'Valor' },
      { field: 'info', header: 'Mensaje' },
      { field: 'idTotem', header: 'N° Tótem' },
      { field: 'resultado', header: 'Resultado' },
    ];
    //FILTROS COLUMNA RESULTADOS
    this.resultados = [
      { label: 'Exitoso', value: 'Exitoso' },
      { label: 'Fallido', value: 'Fallido' },
    ];

    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.SweetAlertService.swalClose();
  }

  async filtrar() {
    console.log("totem: ",this.idtotem);
    
    this.filtrado = false;
    if (this.moment(this.feInicio).isAfter(this.moment(this.feTermino))) {
      this.SweetAlertService.swalError('Seleccione un rango de fechas valido');
    } else if (this.totemSelect === ''){
      this.SweetAlertService.swalError('Seleccione un tótem');
    } else if(this.codigoSelect === ''){
      this.SweetAlertService.swalError('Seleccione un código');
    } else{
      this.filtrado = true;
      var filtros = {
        idTotem: this.totemSelect,
        feInicio: this.feInicio,
        feTermino: this.feTermino,
        idCodigo: this.codigoSelect
      };
      this.SweetAlertService.swalLoading();
      await this.EfectivoService.getLogs(filtros).then((logs) => (this.historiales = logs['data']));
      //await this.TotemService.getTotems().then((totems) => (this.totems = totems));
      this.SweetAlertService.swalClose();
    }
  }

  //EXPORTAR A EXCEL
  exportExcel(filtrados: any) {
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.historiales;
    }

    for (let element of datos) {
      var obj = {
        'N° Registro': element.idHistorial,
        'N° Codigo': element.idCodigo,
        Fecha: this.moment(element.fecha).format('DD-MM-YYYY'),
        Hora: this.moment(element.fecha).format('HH:mm:ss'),
        Mensaje: element.info,
        'N° Tótem': element.idTotem,
        Resultado: element.resultado,
      };
      toExport.push(obj);
    }

    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(toExport);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, 'Historial');
    });
  }
  //CONVERTIR EN EXCEL
  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver) => {
      let EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }

  //exportar a PDF
  exportPdf(filtrados: any) {
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.historiales;
    }
    for (let element of datos) {
      var obj = {
        idHistorial: element.idHistorial,
        idCodigo: element.idCodigo,
        fecha: this.moment(element.fecha).format('DD-MM-YYYY'),
        hora: this.moment(element.fecha).format('HH:mm:ss'),
        info: element.info,
        idTotem: element.idTotem,
        resultado: element.resultado,
      };
      toExport.push(obj);
    }
    var doc = new jsPDF('p', 'pt');
    doc.text('Historial de uso', 50, 30);
    autoTable(doc, { columns: this.exportColumns, body: toExport });
    doc.save('Historial.pdf');
  }
}
