import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import {MessageService} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { Gaveta } from 'src/app/core/models/gaveta';
import { GavetaService } from 'src/app/core/services/http/gaveta.service';
import { DispositivoService } from 'src/app/core/services/http/dispositivo.service';
import * as moment from 'moment';
import {SweetAlertService} from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { Totem } from 'src/app/core/models/totem';
import { SucursalService } from 'src/app/core/services/http/sucursal.service';
import { TotemService } from 'src/app/core/services/http/totem.service';
@Component({
  selector: 'app-gaveta',
  templateUrl: './gaveta.component.html',
  styleUrls: ['./gaveta.component.scss']
})
export class GavetaComponent implements  OnInit {
  moment: any = moment;
  dispositivos: any[];
  gavetaDialog: boolean;
  gavetas: Gaveta[];
  gaveta: Gaveta;
  submitted: boolean;
  totem: Totem[];
  accion;
  titulo;
  oficinas: any[];
  totems: any[];
  TotemsAux: any[];
  oficinaSelect;
  filtrado;
  mensaje;
  totemSelect;
  sucursalSelect;
  constructor(
    private GavetaService: GavetaService,
    private ConfirmationService: ConfirmationService,
    private MessageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private SucursalService: SucursalService,
    private TotemService: TotemService,
    private DispositivoService: DispositivoService,
    private SweetAlertService: SweetAlertService) { }



    async ngOnInit() {
    this.SweetAlertService.swalLoading();
    this.oficinaSelect = "";
    this.sucursalSelect = "";
    await this.TotemService.getTotems().then(totems => this.totems = totems);
    this.TotemsAux = this.totems;
    await this.SucursalService.getSucursales().then(oficinas => this.oficinas = oficinas['data']);
    this.primengConfig.ripple = false;
    this.accion = ""; 
    this.filtrado = false
    this.SweetAlertService.swalClose();
    }

    SucursalSeleccionada(Sucursal)    
    {
      this.TotemsAux = this.totems;
      if (Sucursal != -1) {
        this.TotemsAux = this.TotemsAux.filter(function (array) {
          if (array.idOficina == Sucursal) {
            return array
          }
        });
      }
    }

    async filtrar(){
      if(this.sucursalSelect == "" && this.oficinaSelect == "")
      {
        await this.SweetAlertService.swalError("Debe seleccionar sucursal y totem");
      }
      else{
        this.SweetAlertService.swalLoading();
        this.filtrado = true;
        var filtros = {
          totem: this.oficinaSelect,
          sucursal: this.sucursalSelect  
        };
        await this.GavetaService.getGavetas(filtros).then(gavetas => this.gavetas = gavetas['data']);
        await this.DispositivoService.getDispositivos(filtros).then(dispositivos => this.dispositivos = dispositivos["data"]);   
        this.SweetAlertService.swalClose();
      }
    }

  async openNew() {
    this.gaveta = {};
    this.gaveta.idDispositivo=0;
    this.gaveta.tipo="";
    this.gaveta.tipoDenominacion="";
    this.submitted = false;
    this.gavetaDialog = true;
    this.accion = "Agregar";
    this.titulo = "Nueva gaveta";
  }

  editGaveta(gaveta: Gaveta) {
    this.gaveta = { ...gaveta };
    this.gaveta.fechaInstalacion = this.moment(this.gaveta.fechaInstalacion).format('YYYY-MM-DD');
    this.gavetaDialog = true;
    this.accion = "Actualizar";
    this.titulo = "Modificar gaveta";
  }

  async deleteGaveta(gaveta: Gaveta) {
    
    this.ConfirmationService.confirm({
      message: 'Esta seguro(a) que desea eliminar gaveta N°' + gaveta.idGaveta + " del dispositivo N°" + gaveta.idDispositivo + '?',
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        var gavetaDeleted = {
          "acc": "D",
          "idGaveta": gaveta.idGaveta
        }
        var resp = await this.GavetaService.eliminarGaveta(gavetaDeleted);
        if (resp['status']) {
          this.gaveta = {};
          var filtros = {
            totem: this.oficinaSelect,
            sucursal: this.sucursalSelect  
          };
          await this.GavetaService.getGavetas(filtros).then(gavetas => this.gavetas = gavetas['data']);
          this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'gaveta eliminado', life: 3000 });
        } else {
          this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'La gaveta no se pudo eliminar', life: 3000 });
        }

      }
    });

  }

  hideDialog() {
    this.gavetaDialog = false;
    this.submitted = false;
  }

  async saveGaveta() {
    var aux = 0;
    this.submitted = true;
    if (this.accion === "Agregar") {
      var crearGaveta = {
        "acc": "N",
        "idGaveta": this.gaveta.idGaveta,
        "idDispositivo": this.gaveta.idDispositivo,
        "fechaInstalacion": this.moment(this.gaveta.fechaInstalacion).format('YYYY-MM-DD'),
        "tipo": this.gaveta.tipo,
        "estado": true,
        "tipoDenominacion": this.gaveta.tipoDenominacion
      }
      if (this.gaveta.idDispositivo>0 && this.gaveta.tipo) {
        var resp = await this.GavetaService.administrarGaveta(crearGaveta);
        this.gavetaDialog = false;
        if (resp['status']) {
          this.gaveta = {};
          this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Gaveta agregada', life: 3000 });
        } else {
          this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'La gaveta no se pudo agregar', life: 3000 });
        }

      }

    } else if (this.accion === "Actualizar") {
      //ACTUALIZAR
      if(this.gaveta.fechaInstalacion==="Invalid date"){
        this.gaveta.fechaInstalacion=null;
      }
      var actualizarGaveta = {
        "acc": "U",
        "idGaveta": this.gaveta.idGaveta,
        "idDispositivo": this.gaveta.idDispositivo,
        "fechaInstalacion": this.gaveta.fechaInstalacion,
        "tipo": this.gaveta.tipo,
        "estado": true,
        "tipoDenominacion": this.gaveta.tipoDenominacion
      }
      if (this.gaveta.idDispositivo>0 && this.gaveta.tipo) {
        var resp = await this.GavetaService.administrarGaveta(actualizarGaveta);
        this.gavetaDialog = false;
        this.gaveta = {};
        if (resp['status']) {
          this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Gaveta actualizada', life: 3000 });
        } else {

          this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'No se pudo actualizar los datos de la gaveta', life: 4000 });
        }
      }
    }
    //ACTUALIZAR LISTA DE SUCURSALES
    var filtros = {
      totem: this.oficinaSelect,
      sucursal: this.sucursalSelect  
    };
    await this.GavetaService.getGavetas(filtros).then(gavetas => this.gavetas = gavetas['data']);
  }

  exportExcel(filtrados: any) {
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.gavetas;
    }

    for (let element of datos) {
      var obj = {
        'ID Gaveta': element.idGaveta,
        'ID Dispositivo al que pertenece':element.idDispositivo,
        //'Tipo': element.tipo,
        'Fecha de registro': this.moment(element.fechaIngreso).format('DD-MM-YYYY')
        //'Estado': element.estado,
      }
      toExport.push(obj);
    }

    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(toExport);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Gavetas");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }


}
