import { Component, OnInit } from '@angular/core';
import { Sucursal } from 'src/app/core/models/sucursal';
import { SucursalService } from 'src/app/core/services/http/sucursal.service';
import { ConfirmationService } from 'primeng/api';
import {MessageService} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import {SweetAlertService} from 'src/app/core/services/sweet-alert/sweet-alert.service';
@Component({
  selector: 'app-sucursal',
  templateUrl: './sucursal.component.html',
  styles: [`
        :host ::ng-deep .p-dialog .product-image {
            width: 150px;
            margin: 0 auto 2rem auto;
            display: block;
        }
    `],
  styleUrls: ['./sucursal.component.scss']
})
export class SucursalComponent implements OnInit {
  sucursalDialog: boolean;
  sucursales: Sucursal[];
  sucursal: Sucursal;
  selectedSucursales: Sucursal[];
  submitted: boolean;
  accion;
  titulo;
  constructor(
    private SucursalService: SucursalService,
    private ConfirmationService: ConfirmationService,
    private MessageService: MessageService, 
    private primengConfig: PrimeNGConfig,
    private SweetAlertService: SweetAlertService) { }

  async ngOnInit() {
    this.primengConfig.ripple = true;
    this.accion = "";
    this.SweetAlertService.swalLoading();
    await this.SucursalService.getSucursales().then(sucursales => this.sucursales = sucursales['data']);
    this.SweetAlertService.swalClose();
  }

  async openNew() {
    this.sucursal={};
    this.sucursal.estado=true;
    this.submitted = false;
    this.sucursalDialog = true;
    this.accion = "Agregar";
    this.titulo="Nueva sucursal";
  }

  editSucursal(sucursal: Sucursal) {
    this.sucursal = { ...sucursal };
    this.sucursalDialog = true;
    this.accion = "Actualizar";
    this.titulo="Modificar sucursal";
  }

  async deleteSucursal(sucursal: Sucursal) {
    this.ConfirmationService.confirm({
      message: 'Esta seguro(a) que desea eliminar la sucursal: ' + sucursal.nombre + '?',
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        var sucursalDeleted = {
          "acc": "D",
          "id": parseInt(sucursal.idOficina),
          "nombre": sucursal.nombre,
          "estado": false
        }
        var resp = await this.SucursalService.deleteSucursal(sucursalDeleted);
        if(resp['status']){
          await this.SucursalService.getSucursales().then(sucursales => this.sucursales = sucursales['data']);
          this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Sucursal eliminada', life: 3000 });
        }else{
          this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'La sucursal no se pudo eliminar', life: 3000 });
        }
        this.sucursal= {};
      }
    });
    
  }

  hideDialog() {
    this.sucursalDialog = false;
    this.submitted = false;
  }

  async saveSucursal() {
    this.submitted = true;
    if (this.accion === "Agregar") {
      var crearSucursal = {
        "acc": "N",
        "nombre": this.sucursal.nombre,
        "estado": true
      }
      if(this.sucursal.nombre){
        var resp = await this.SucursalService.agregarSucursal(crearSucursal);
        this.sucursalDialog = false;
          this.sucursal={};
        if (resp['status']) {
          
          await this.SucursalService.getSucursales().then(sucursales => this.sucursales = sucursales['data']);
          this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Sucursal agregada', life: 3000 });
        }else{
          this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'La sucursal no se pudo agregar', life: 3000 });
        }
      }
      
    } else if(this.accion==="Actualizar") {
      //ACTUALIZAR
      
      
      var actualizarSucursal = {
        "acc": "U",
        "id": this.sucursal.idOficina,
        "nombre": this.sucursal.nombre,
        "estado": this.sucursal.estado
      }
      if(this.sucursal.nombre){
        var resp = await this.SucursalService.agregarSucursal(actualizarSucursal);
        this.sucursalDialog = false;
        this.sucursal={};
        if (resp['status']) {
          await this.SucursalService.getSucursales().then(sucursales => this.sucursales = sucursales['data']);
          this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Sucursal actualizada', life: 3000 });
        }else{
          this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'La sucursal no se pudo actualizar', life: 3000 });
        }
      }
      
    }
    //ACTUALIZAR LISTA DE SUCURSALES
    await this.SucursalService.getSucursales().then(sucursales => this.sucursales = sucursales['data']);

  }

  exportExcel(filtrados: any) {
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.sucursales;
    }

    for (let element of datos) {
      var obj = {
        'ID Sucursal': element.idOficina,
        'Nombre': element.nombre,
        //'Estado': element.estado,
      }
      toExport.push(obj);
    }

    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(toExport);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Sucursales");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
}
