import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable({
    providedIn: 'root'
})
export class TotemService {
    headers: HttpHeaders = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
    });

    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient
    ) { }
    //GET TOTEMS
    async getTotems(): Promise<any> {
        const API_URL = await this.configService.getConfig();
        //const url = `${API_URL['api_efectivo']}/Atencion/BuscarTotem`;
        const url=`${API_URL['api_efectivo']}/Totem/getTotem`;
        try {
            return await this.httpClient.get(url,{ headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    
    async eliminarTotem(totem): Promise<any> {
        const API_URL = await this.configService.getConfig();
        //const url = `${API_URL['api_efectivo']}/Atencion/BuscarTotem`;
        const url=`${API_URL['api_efectivo']}/Totem/AdminTotem`;
        try {
            return await this.httpClient.post(url, totem,{ headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async administrarTotem(totem): Promise<any> {
        const API_URL = await this.configService.getConfig();
        //const url = `${API_URL['api_efectivo']}/Atencion/BuscarTotem`;
        const url=`${API_URL['api_efectivo']}/Totem/AdminTotem`;
        try {
            return await this.httpClient.post(url, totem,{ headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    
}