import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable({
    providedIn: 'root'
})
export class EfectivoService {
    headers: HttpHeaders = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
    });

    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient
    ) { }

    //GET MOVIMIENTOS
    async getMovimientos(filtros): Promise<any>{
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_efectivo']}/Movimiento/getMovimiento?IdTotem=`+filtros["idTotem"]+`&FechaInicio=`+filtros["feInicio"]+`&FechaFinal=`+filtros["feTermino"]+`&sucursal=`+filtros["sucursal"];
        try {
            return await this.httpClient.get(url,{ headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async getCodigo(): Promise<any>{
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_efectivo']}/Historial/getCodigo`;
        try {
            return await this.httpClient.get(url,{ headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async getOficinas(): Promise<any>{
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_efectivo']}/Oficina/getOficina`;
        try {
            return await this.httpClient.get(url,{ headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    //OBTENER REGISTROS
    async getRegistros(filtros): Promise<any>{
        
        
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_efectivo']}/Registro/getRegistro?sucursal=`+filtros["sucursal"]+`&FechaInicio=`+filtros["feInicio"]+`&FechaFin=`+filtros["feTermino"];
        //const url = "http://172.16.32.56/Efectivo/api/Registro/getRegistro?sucursal=14&FechaInicio=2021-01-01&FechaFin=2021-04-13";
        try {
            return await this.httpClient.get(url, { headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async getLogs(filtros): Promise<any>{
        console.log("SOLICITUD: ",filtros);
        
        const API_URL = await this.configService.getConfig();
        const url =`${API_URL['api_efectivo']}/Historial/getHistorial?IdTotem=`+filtros["idTotem"]+`&FechaInicio=`+filtros["feInicio"]+`&FechaFinal=`+filtros["feTermino"]+`&idCodigo=`+filtros["idCodigo"];
        try {
            return await this.httpClient.get(url,{ headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async getEstados(idTotem): Promise<any>{
        
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_efectivo']}/EstadoSalud/getEstadoSalud?IdTotem=`+idTotem;
        //const url="http://172.16.32.56/efectivo/api/EstadoSalud/getEstadoSalud?IdTotem="+idTotem;
        try {
            return await this.httpClient.get(url, { headers: this.headers }).toPromise();
            // return {
            //     status: true,
            //     code: 200,
            //     data: [
            //         {"idTotem": 1, 
            //         "idOficina": 2,
            //         "gavetas": [
            //            { "diGaveta":1, "tipo":"Billete", "estado":1},
            //            { "diGaveta":2, "tipo":"Moneda", "estado":1}
            //         ],
            //     },
            //     {"idTotem": 2, 
            //         "idOficina": 2,
            //         "gavetas": [
            //            { "diGaveta":1, "tipo":"Billete", "estado":1},
            //            { "diGaveta":2, "tipo":"Moneda", "estado":1}
            //         ],
            //     },
    
            //     ]
            // }
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async getVentaDelDia(filtros): Promise<any>{
        
        const API_URL = await this.configService.getConfig();
        //const url = `${API_URL['api_efectivo']}/Movimiento/SetCierreX`; /Dashboard/VentaDelDia?IdTotem=
        const url= `${API_URL['api_efectivo']}/Dashboard/VentaDelDia?IdOficina=`+filtros["IdOficina"];
        try {
            return await this.httpClient.get(url, { headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async getResumenDelDia(filtros): Promise<any>{
        
        const API_URL = await this.configService.getConfig();
        //const url = `${API_URL['api_efectivo']}/Movimiento/SetCierreX`; /Dashboard/VentaDelDia?IdTotem=
        const url= `${API_URL['api_efectivo']}/Dashboard/ResumenDelDia?IdOficina=`+filtros["IdOficina"];
        try {
            return await this.httpClient.get(url, { headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async getResumenDelMes(filtros): Promise<any>{
        
        const API_URL = await this.configService.getConfig();
        //const url = `${API_URL['api_efectivo']}/Movimiento/SetCierreX`; /Dashboard/VentaDelDia?IdTotem=
        const url= `${API_URL['api_efectivo']}/Dashboard/ResumenDelMes?IdOficina=`+filtros["IdOficina"];
        try {
            return await this.httpClient.get(url, { headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async getResumenDelAno(filtros): Promise<any>{
        
        const API_URL = await this.configService.getConfig();
        //const url = `${API_URL['api_efectivo']}/Movimiento/SetCierreX`; /Dashboard/VentaDelDia?IdTotem=
        const url= `${API_URL['api_efectivo']}/Dashboard/ResumenDelAno?IdOficina=`+filtros["IdOficina"];
        try {
            return await this.httpClient.get(url, { headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async getCierreX(IdTotem: number, sucursal: string): Promise<any>{
        
        const API_URL = await this.configService.getConfig();
        //const url = `${API_URL['api_efectivo']}/Movimiento/SetCierreX`;
        
        const url= `${API_URL['api_efectivo']}/Movimiento/SetCierreX?IdTotem=`+IdTotem+`&sucursal=`+sucursal
        try {
            return await this.httpClient.get(url, { headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async getCierreZ(IdTotem: number, sucursal: string): Promise<any>{
        
        const API_URL = await this.configService.getConfig();
        //const url = `${API_URL['api_efectivo']}/Movimiento/SetCierreZ`;
        const url= `${API_URL['api_efectivo']}/Movimiento/SetCierreZ?IdTotem=`+IdTotem+`&sucursal=`+sucursal
        try {
            return await this.httpClient.get(url, { headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    async getCierreFecha(IdTotem: number, sucursal: string, feInicio: string, feTermino: string): Promise<any>{
        
        const API_URL = await this.configService.getConfig();
        //const url = `${API_URL['api_efectivo']}/Movimiento/SetCierreZ`;
        const url= `${API_URL['api_efectivo']}/Movimiento/getCierreFecha?IdTotem=`+IdTotem+`&sucursal=`+sucursal+`&FechaInicio=`+feInicio+`&FechaFinal=`+feTermino;
        try {
            return await this.httpClient.get(url, { headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
}
