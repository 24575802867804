import { Component } from '@angular/core';
import {AuthService} from 'src/app/core/services/auh/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public isCollapsed = false;

  constructor(private AuthService: AuthService) { }

  ngOnInit() {
  }

  salir(e: any) {
    e.preventDefault();
    this.AuthService.logout();
  }

}
