import { BrowserModule } from '@angular/platform-browser';
import { ToastrModule  } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { UserIdleModule } from 'angular-user-idle';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import {DataTablesModule} from 'angular-datatables';
import {TableModule} from 'primeng/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ButtonModule} from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import {ChartModule} from 'primeng/chart';
import { StatusComponent } from './components/status/status.component';
import { ReportComponent } from './components/report/report.component';
import { LogsComponent } from './components/logs/logs.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { TotemComponent } from './components/admin/totem/totem.component';
import { SucursalComponent } from './components/admin/sucursal/sucursal.component';
import { UsuarioComponent } from './components/admin/usuario/usuario.component';
import { DispositivoComponent } from './components/admin/dispositivo/dispositivo.component';
import { GavetaComponent } from './components/admin/gaveta/gaveta.component';
import { DenominacionComponent } from './components/admin/denominacion/denominacion.component';
////////////////////////////
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {FileUploadModule} from 'primeng/fileupload';
import {ToolbarModule} from 'primeng/toolbar';
import {RatingModule} from 'primeng/rating';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputNumberModule} from 'primeng/inputnumber';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { VonConfirmationModule } from '@von-development-studio/primeng-confirmation-dialog';
import { ChartComponent } from './components/dashboard/chart/chart.component';
import { MovimientoComponent } from './components/movimiento/movimiento.component';
import { RegistroComponent } from './components/registro/registro.component';
import { CierreZComponent } from './components/cierreZ/cierre-z.component';
import { CierreXComponent } from './components/cierre-x/cierre-x.component';
import { ReportePorFechaComponent } from './components/reporte-por-fecha/reporte-por-fecha.component';
import {SidebarModule} from 'primeng/sidebar';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    HeaderComponent,
    StatusComponent,
    ReportComponent,
    LogsComponent,
    DashboardComponent,
    SidebarComponent,
    TotemComponent,
    SucursalComponent,
    UsuarioComponent,
    DispositivoComponent,
    GavetaComponent,
    DenominacionComponent,
    ChartComponent,
    MovimientoComponent,
    RegistroComponent,
    CierreZComponent,
    CierreXComponent,
    ReportePorFechaComponent
    
  ],
  imports: [
    BrowserModule,
    ToastModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot({
      progressBar: true
    }),
    UserIdleModule.forRoot({ idle: 240, timeout: 60 }),
    ReactiveFormsModule,
    ModalModule.forRoot(),
    FormsModule,
    ChartsModule,
    DataTablesModule,
    TableModule,
    BrowserAnimationsModule,
    ButtonModule,
    VonConfirmationModule,
    RippleModule,
    ChartModule,
    CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    RadioButtonModule,
    InputNumberModule,
    InputTextareaModule,
    ConfirmDialogModule,
    SidebarModule
  ],
  entryComponents: [],
  providers: [MessageService, ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
