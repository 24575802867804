<app-header></app-header>
<div id="main">
    <app-sidebar></app-sidebar>
    <div class="text-center">
        <img width="25%" src="./assets/img/totalpack.png">
    </div>
    <div class="offset-1 col-10">
        <p-toast></p-toast>
        <div class="card">
            <p-toolbar styleClass="p-mb-4">
                <ng-template pTemplate="left">
                    <button pButton pRipple label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2"
                        (click)="openNew()"></button>
                </ng-template>
                <ng-template pTemplate="right">
                    <button pButton pRipple label="Exportar a excel" (click)="exportExcel(dt.filteredValue)"
                        icon="pi pi-upload" class="p-button-help"></button>
                </ng-template>
            </p-toolbar>

            <p-table #dt [value]="totems" [rows]="10" [paginator]="true"
                [globalFilterFields]="['idTotem', 'idOficina', nombreTotem, nombreOficina ]" [rowHover]="true"
                dataKey="totem.idTotem"
                currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} encontrados"
                [showCurrentPageReport]="true">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <h5 class="p-m-0">Tótems</h5>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Buscar..." />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="idTotem">ID Tótem <p-sortIcon field="idTotem"></p-sortIcon>
                        </th>
                        <th pSortableColumn="idOficina">Sucursal a la que pertenece <p-sortIcon field="idOficina">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="nombreTotem">Nombre Tótem<p-sortIcon field="nombreTotenm"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-totem>
                    <tr *ngIf="totem.estado === true">
                        <td>{{totem.idTotem}}</td>
                        <td>{{totem.idOficina}} - {{totem.nombreOficina}}</td>
                        <td>{{totem.nombreTotem}}</td>
                        <td>
                            <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                (click)="editTotem(totem)" *ngIf="totem.estado === true"></button>
                            <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning"
                                (click)="deleteTotem(totem)" *ngIf="totem.estado === true"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        Existen {{totems ? totems.length : 0 }} tótems.
                    </div>
                </ng-template>
            </p-table>
        </div>
        <p-dialog [(visible)]="totemDialog" [style]="{width: '450px'}" [header]="titulo" [modal]="true"
            styleClass="p-fluid">
            <ng-template pTemplate="content">
                <div class="p-field">
                    <label for="idOficina">N° de oficina a la que pertenece</label>
                    <select id="idOficina" [(ngModel)]="totem.idOficina" required autofocus class="form-control">
                        <option value="0">Seleccione oficina</option>
                        <option *ngFor="let oficina of oficinas" [value]="oficina.idOficina">{{oficina.idOficina}} - {{oficina.nombre}}
                        </option>
                    </select>
                    <label for="nombre">Nombre</label><br>
                    <input type="text" [(ngModel)]="totem.nombre">
                    <small class="p-invalid" *ngIf="submitted && totem.idOficina==0">El numero de sucursal es obligatorio</small>
                </div>
                <br>
                <button class="btn btn-primary" (click)="saveTotem()">{{accion}}</button>

            </ng-template>

        </p-dialog>
        <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    </div>
</div>