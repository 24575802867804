import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { EfectivoService } from 'src/app/core/services/http/efectivo.service';
import { Registro } from 'src/app/core/models/registro';
//PDF
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable'
//
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { TotemService } from 'src/app/core/services/http/totem.service';
import { SucursalService } from 'src/app/core/services/http/sucursal.service';
import { Totem } from 'src/app/core/models/totem';
@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit {
  
  filtrado;
  moment: any = moment;
  cols: any[];
  exportColumns: any[];
  registros: Registro[];
  movimientos: any[];
  //NGMODELS
  feInicio;
  feTermino;
  idMovimiento;
  TotalIngresor: number;
  totemSelect;
  sucursalSelect;
  totems: Totem[];
  oficinas: any[];
  hoy=moment();
  hoyDia: any;

  constructor(
    private EfectivoService: EfectivoService,
    private SweetAlertService: SweetAlertService,
    private TotemService: TotemService,
    private SucursalService: SucursalService
    ) { }

  async ngOnInit() {
    //LISTAR MOVIMIENTOS DE SELECT
    this.SweetAlertService.swalLoading();
    this.totemSelect = "";
    this.sucursalSelect = "";
    this.registros = [];
    await this.TotemService.getTotems().then(totems => this.totems = totems);
    await this.SucursalService.getSucursales().then(oficinas => this.oficinas = oficinas['data']);
    this.feInicio = this.moment(new Date()).format('YYYY-MM-DD');
    this.feTermino = this.moment(new Date()).format('YYYY-MM-DD');
    this.hoyDia = moment().format('DD/MM/YYYY')
    this.idMovimiento = "";
    this.cols = [
      { field: 'idRegistro', header: 'ID Movimiento' },
      { field: 'montoIngreso', header: 'Monto Ingresado' },
      { field: 'rutUsuario', header: 'Rut Usuario' },
      { field: 'rutCliente', header: 'Rut Cliente' },
      { field: 'montoPago', header: 'Monto t. transacción' },
      //{ field: 'idPago', header: 'ID Pago' },
      { field: 'fecha', header: 'Fecha y Hora' },
      
    ];
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    this.SweetAlertService.swalClose();
  }

  async filtrar() {
    this.filtrado = false;
    this.SweetAlertService.swalLoading();
    if (this.moment(this.feInicio).isAfter(this.moment(this.feTermino))) {
      this.SweetAlertService.swalError('Ingrese un rango de fechas valido');
    }
    else {
      this.filtrado = true;  
      var filtros = {
        sucursal: this.sucursalSelect,
        feInicio: this.feInicio,
        feTermino: this.feTermino
      };
      this.SweetAlertService.swalLoading();
      var respRegistros = await this.EfectivoService.getRegistros(filtros)
      
      if(respRegistros['status'])
      {
        this.registros = respRegistros['data'];
      }
      else
      {
        await this.SweetAlertService.swalError('Error al conectarse con el servicio');
      }
      await this.RealizarSuma()
      this.SweetAlertService.swalClose();
    }
  }

  RealizarSuma() {
    this.TotalIngresor = 0;
    if (this.registros !== undefined) {
      this.registros.forEach(element => {
        if(element.tipoMovimiento == "C")
        {
          this.TotalIngresor += element.montoIngreso
        }
        else if(element.tipoMovimiento == "T")
        {
          this.TotalIngresor += element.montoIngreso
        }
        else{
          this.TotalIngresor -= element.montoIngreso
        }
      });
    }
    return this.TotalIngresor;
  }

  //EXPORTAR A EXCEL
  exportExcel(filtrados: any) {
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.registros;
    }

    for (let element of datos) {
      var obj = {
        'N° Registro': element.idRegistro,
        'Rut usuario': element.rutUsuario,
        'Rut cliente': element.rutCliente,
        'Monto pagado': element.montoPago
      }
      toExport.push(obj);
    }

    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(toExport);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Registros");
    });
  }
  //CONVERTIR EN EXCEL
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  //exportar a PDF
  exportPdf(filtrados: any) {

    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.registros;
    }
    for (let element of datos) {
      var obj = {
        'idRegistro': element.idRegistro,
        'rutUsuario': element.rutUsuario,
        'rutCliente': element.rutCliente,
        'montoPago': element.montoPago
      }
      toExport.push(obj);
    }
    var doc = new jsPDF('p', 'pt');
    doc.text("Registros", 50, 30);
    autoTable(doc, { columns: this.exportColumns, body: toExport })
    doc.save('Registros.pdf');
  }

}
