import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable({
    providedIn: 'root'
})
export class DispositivoService {
    headers: HttpHeaders = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
    });

    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient
    ) { }
    
    //GET DISPOSITIVOS
    async getDispositivos(filtros): Promise<any> {
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_efectivo']}/Dispositivo/getDispositivos?sucursal=`+filtros["sucursal"]+`&totem=`+filtros["totem"]
        try {
            return await this.httpClient.get(url, { headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
    //AGREGAR Y ACTUALIZAR DISPOSITIVO
    async administrarDispotivo(dispositivo){
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_efectivo']}/Dispositivo/AdminDis`;
        try {
            return await this.httpClient.post(url, dispositivo,{ headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }

    //ELIMINAR/DESHABILITAR DISPOSITIVO
    async eliminarDispotivo(dispositivo){
        const API_URL = await this.configService.getConfig();
        const url = `${API_URL['api_efectivo']}/Dispositivo/AdminDis`;
        try {
            return await this.httpClient.post(url, dispositivo,{ headers: this.headers }).toPromise();
        } catch (error) {
            return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
        }
    }
 
}