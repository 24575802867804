<app-header></app-header>
<div id="main">
    <app-sidebar></app-sidebar>
    <!--CONTENIDO-->
    <div class="text-center">
        <img width="25%" src="./assets/img/totalpack.png">
    </div>
    <div class="offset-1 col-10">
        <div class="offset-3 col-6" align="center">
            <div class="row">
                <select class="form-control" (change)="filtrar()" [(ngModel)]="totemSelect" style="width: 370px; position: relative; left: 270px;">
                    <option value="">Seleccione tótem</option>
                    <option *ngFor="let totem of totems" [value]="totem.idTotem">{{totem.idTotem}}-{{totem.nombreTotem}}</option>
                    <!-- <option value="-1">Todos</option> -->
                </select>
            </div>
        </div>
        <br>
        <div class="row">
            <p-table [value]="estados" *ngIf="estados.length>0" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} encontrados" 
                paginatorPosition="bottom" [globalFilterFields]="['tipo','estado', 'mensajeD']" styleClass="p-datatable-gridlines">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-left p-ml-auto float-right">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value, 'contains')"
                                placeholder="Buscar..." />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="tipo">Tipo <p-sortIcon field="tipo"></p-sortIcon></th>
                        <th pSortableColumn="estado">Estado <p-sortIcon field="estado"></p-sortIcon></th>
                        <th pSortableColumn="mensajeD">Mensaje <p-sortIcon field="mensajeD"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-estados>
                    <tr>
                        <td>{{estados.tipo}}</td>
                        <td *ngIf="estados.estado == false" style="color: red">Desactivado</td>
                        <td *ngIf="estados.estado == true" style="color: green">Activado</td>
                        <td *ngIf="estados.estado == false">{{estados.mensajeD}}</td>
                        <td *ngIf="estados.estado == true">{{estados.mensajeD}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

</div>