import { Component, OnInit, OnDestroy } from '@angular/core';
import { EfectivoService } from 'src/app/core/services/http/efectivo.service';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { interval, Subscription } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { TotemService } from 'src/app/core/services/http/totem.service';
import { Totem } from '../../core/models/totem';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit, OnDestroy {
  estados: any[];
  tiempo: number;
  TimerAInicio: Subscription;
  totems: any[];
  idTotem;
  totemSelect;
  totem: Totem;
  constructor(
    private TotemService: TotemService,
    private EfectivoService: EfectivoService,
    private SweetAlertService: SweetAlertService,
  ) { }

  async ngOnInit() {
    this.estados = [];
    this.SweetAlertService.swalLoading();
    await this.TotemService.getTotems().then(totems => this.totems = totems);
    this.totemSelect = "";
    this.SweetAlertService.swalClose();
    this.tiempo = 0;
    this.timer();

  }

  ngOnDestroy() {
    this.TimerAInicio.unsubscribe();
    this.tiempo = 0;
  }

  async filtrar() {
    if (this.totemSelect !== "") {
      this.SweetAlertService.swalLoading();
      await this.EfectivoService.getEstados(this.totemSelect).then(estados => this.estados = estados['data']);
      this.SweetAlertService.swalClose();
    } else {
      this.estados = [];
    }
  }

  async timer() {
    var seconds = interval(2000);
    this.TimerAInicio = seconds.pipe(timeout(3100)).subscribe(
      value => {
        this.tiempo++;
        //console.log("cada 2 segundos")
      });
  }

}
