import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import {ReportComponent} from 'src/app/components/report/report.component';
import {LogsComponent} from 'src/app/components/logs/logs.component';
import {StatusComponent} from 'src/app/components/status/status.component';
import { TotemComponent } from 'src/app/components/admin/totem/totem.component';
import { SucursalComponent } from 'src/app/components/admin/sucursal/sucursal.component';
import { UsuarioComponent } from 'src/app/components/admin/usuario/usuario.component';
import { DispositivoComponent } from 'src/app/components/admin/dispositivo/dispositivo.component';
import { GavetaComponent } from 'src/app/components/admin/gaveta/gaveta.component';
import { DenominacionComponent } from 'src/app/components/admin/denominacion/denominacion.component';
import { MovimientoComponent } from 'src/app/components/movimiento/movimiento.component';
import { RegistroComponent } from 'src/app/components/registro/registro.component';
import {CierreXComponent} from 'src/app/components/cierre-x/cierre-x.component';
import {CierreZComponent} from 'src/app/components/cierreZ/cierre-z.component';
import {ReportePorFechaComponent} from 'src/app/components/reporte-por-fecha/reporte-por-fecha.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'report', component: ReportComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'logs', component: LogsComponent, canActivate: [AuthGuard] },
  { path: 'status', component: StatusComponent, canActivate: [AuthGuard] },
  { path: 'totem', component: TotemComponent, canActivate: [AuthGuard] },
  { path: 'sucursal', component: SucursalComponent, canActivate: [AuthGuard] },
  { path: 'usuario', component: UsuarioComponent, canActivate: [AuthGuard] },
  { path: 'dispositivo', component: DispositivoComponent, canActivate: [AuthGuard] },
  { path: 'gaveta', component: GavetaComponent, canActivate: [AuthGuard] },
  { path: 'denominacion', component: DenominacionComponent, canActivate: [AuthGuard] },
  { path: 'movimiento', component: MovimientoComponent, canActivate: [AuthGuard] },
  { path: 'registros', component: RegistroComponent, canActivate: [AuthGuard] },
  { path: 'cierreZ', component: CierreZComponent, canActivate: [AuthGuard]},
  { path: 'cierreX', component: CierreXComponent, canActivate: [AuthGuard]},
  { path: 'cierrePorFecha', component: ReportePorFechaComponent, canActivate: [AuthGuard]},
  { path: '', pathMatch: 'full', redirectTo: 'report' },
  { path: '**', pathMatch: 'full', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
