import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  display;
  constructor() { }

  Usuario;
  ngOnInit(): void {
    this.Usuario = JSON.parse(localStorage.getItem("DatosUsu"));
  }

  // openNav(){
  //   document.getElementById("mySidenav").style.width = "250px";
  //   document.getElementById("main").style.marginLeft = "250px";
  //   document.getElementById("boton").style.display = "none";
  //   document.getElementById("boton2").style.display = "inline";
  // }

  // closeNav() {
  // document.getElementById("mySidenav").style.width = "0";
  // document.getElementById("main").style.marginLeft = "0";
  // document.getElementById("boton").style.display = "inline";
  // document.getElementById("boton2").style.display = "none";
  // }
}
