import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import {MessageService} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import * as moment from 'moment';
import { DenominacionService } from 'src/app/core/services/http/denominacion.service';
import { Denominacion } from 'src/app/core/models/denominacion';
import { GavetaService } from 'src/app/core/services/http/gaveta.service';
import {SweetAlertService} from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { interval, Subscription } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { Totem } from 'src/app/core/models/totem';
import { SucursalService } from 'src/app/core/services/http/sucursal.service';
import { TotemService } from 'src/app/core/services/http/totem.service';
@Component({
  selector: 'app-denominacion',
  templateUrl: './denominacion.component.html',
  styleUrls: ['./denominacion.component.scss']
})
export class DenominacionComponent implements OnInit {
  gavetas: any[];
  tiempo: number;
  moment: any = moment;
  TimerAInicio: Subscription;
  denominacionDialog: boolean;
  denominaciones: Denominacion[];
  denominacion: Denominacion;
  submitted: boolean;
  accion;
  titulo;
  TotalIngresor=0;
  gavetaSelect;
  totems: any[];
  TotemsAux: any[];
  oficinas: any[];
  oficinaSelect;
  filtrado;
  mensaje;
  sucursalSelect;
  constructor(
    private DenominacionService: DenominacionService,
    private ConfirmationService: ConfirmationService,
    private MessageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private GavetaService: GavetaService,
    private SweetAlertService: SweetAlertService,
    private SucursalService: SucursalService,
    private TotemService: TotemService,) { }

  async ngOnInit() {
    this.SweetAlertService.swalLoading();
    this.oficinaSelect = "";
    this.sucursalSelect = "";
    await this.TotemService.getTotems().then(totems => this.totems = totems);
    this.TotemsAux = this.totems;
    await this.SucursalService.getSucursales().then(oficinas => this.oficinas = oficinas['data']);
    this.primengConfig.ripple = false;
    this.accion = ""; 
    this.filtrado = false
    this.SweetAlertService.swalClose();
  }

  SucursalSeleccionada(Sucursal)    
  {
      this.TotemsAux = this.totems;
      if (Sucursal != -1) {
        this.TotemsAux = this.TotemsAux.filter(function (array) {
          if (array.idOficina == Sucursal) {
            return array
          }
        });
      }
  }

  // ngOnDestroy() {
  //   this.TimerAInicio.unsubscribe();
  //   this.tiempo = 0;
  // }

  async timer() {
    var seconds = interval(2000);
    this.TimerAInicio = seconds.pipe(timeout(3100)).subscribe(
      value => {
        this.tiempo++;
        //console.log("cada 2 segundos")
      });
  }

  async openNew() {
    this.denominacion = {};
    this.denominacion.idGaveta=0;
    this.denominacion.denominacion1="";
    this.denominacion.tipo="";
    this.submitted = false;
    this.denominacionDialog = true;
    this.accion = "Agregar";
    this.titulo = "Nueva denominación";
  }

  editDenominacion(denominacion: Denominacion) {
    this.denominacion = { ...denominacion };
    //this.gaveta.fechaInstalacion = this.moment(this.gaveta.fechaInstalacion).format('YYYY-MM-DD');
    this.denominacionDialog = true;
    this.accion = "Actualizar";
    this.titulo = "Modificar denominación";
  }

  async filtrarPorGaveta()
  {
    var filtros = {
      totem: this.oficinaSelect,
      sucursal: this.sucursalSelect  
    };
      await this.DenominacionService.getDenominaciones(filtros).then(denominaciones => this.denominaciones = denominaciones['data']);
      await this.GavetaService.getGavetas(filtros).then(gavetas => this.gavetas = gavetas['data']);
  }

  async filtrar() {
    if(this.sucursalSelect == "" && this.oficinaSelect == "")
    {
      await this.SweetAlertService.swalError("Debe seleccionar sucursal y totem");
    }
    else{
      this.SweetAlertService.swalLoading();
      this.filtrado = true;
      var filtros = {
        totem: this.oficinaSelect,
        sucursal: this.sucursalSelect  
      };
      this.SweetAlertService.swalLoading();
      this.denominaciones = await this.DenominacionService.getDenominaciones(filtros).then(denominaciones => this.denominaciones = denominaciones['data']);
      await this.GavetaService.getGavetas(filtros).then(gavetas => this.gavetas = gavetas['data']);
      this.SweetAlertService.swalClose();
    }
    if (this.gavetaSelect !== "") {
      this.SweetAlertService.swalLoading();
      this.RealizarSuma();
      this.SweetAlertService.swalClose();
    } else {
      this.denominaciones = [];
    }
    
  }

  RealizarSuma() {
    this.TotalIngresor = 0;
    if (this.denominaciones !== undefined) {
      this.denominaciones.forEach(element => {
          this.TotalIngresor += element.cantidad;
      });
    }
    return this.TotalIngresor;
  }

  async deleteDenominacion(denominacion: Denominacion) {
    console.log("a",denominacion);
    
    this.ConfirmationService.confirm({
      message: 'Esta seguro(a) que desea eliminar la denominación ' + denominacion.denominacion1 + " de la gaveta N° "+ denominacion.idGaveta + '?',
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        var denominacionDeleted = {
          "acc": "D",
          "idDenominacion": denominacion.idDenominacion
        }
        var resp = await this.DenominacionService.eliminarDenominacion(denominacionDeleted);
        if (resp['status']) {
          this.denominacion = {};
          await this.DenominacionService.getDenominaciones(denominacion).then(denominaciones => this.denominaciones = denominaciones['data']);
          this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Denominación eliminada', life: 3000 });
        } else {
          this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'La denominación no se pudo eliminar', life: 3000 });
        }

      }
    });

  }

  hideDialog() {
    this.denominacionDialog = false;
    this.submitted = false;
  }
///////////////////////////////////////////////////////////////////////////////////
  async saveDenominacion() {
    var aux = 0;
    this.submitted = true;
    if (this.accion === "Agregar") {
      var crearDenominacion = {
        "acc": "N",
        "idDenominacion": this.denominacion.idDenominacion,
        "idGaveta": this.denominacion.idGaveta,
        "tipoGaveta": this.denominacion.tipoGaveta,
        "denomiacion": this.denominacion.denominacion1,
        "tipo": this.denominacion.tipo,
        "cantidad": this.denominacion.cantidad,
        "estado": true
      }
      if (this.denominacion.idGaveta>0 && this.denominacion.denominacion1 && this.denominacion.tipo && this.denominacion.cantidad>=0) {
        var resp = await this.DenominacionService.administrarDenominacion(crearDenominacion);
        this.denominacionDialog = false;
        if (resp['status']) {
          this.denominacion = {};
          this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Denominación agregada', life: 3000 });
        } else {
          this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'La denominación no se pudo agregar', life: 3000 });
        }

      }

    } else if (this.accion === "Actualizar") {

      var actualizarDenominacion = {
        "acc": "U",
        "idDenominacion": this.denominacion.idDenominacion,
        "idGaveta": this.denominacion.idGaveta,
        "tipoGaveta": this.denominacion.tipoGaveta,
        "denomiacion": this.denominacion.denominacion1,
        "tipo": this.denominacion.tipo,
        "cantidad": this.denominacion.cantidad,
        "estado": true
      }
      if (this.denominacion.idGaveta>0 && this.denominacion.denominacion1 && this.denominacion.tipo && this.denominacion.cantidad>=0) {
        var resp = await this.DenominacionService.administrarDenominacion(actualizarDenominacion);
        this.denominacionDialog = false;
        this.denominacion = {};
        if (resp['status']) {
          this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Denominación actualizada', life: 3000 });
        } else {

          this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'No se pudo actualizar los datos de la denominación', life: 4000 });
        }
      }
    }
    //ACTUALIZAR LISTA DE SUCURSALES
    var filtros = {
      totem: this.oficinaSelect,
      sucursal: this.sucursalSelect  
    };
    await this.DenominacionService.getDenominaciones(filtros).then(denominaciones => this.denominaciones = denominaciones['data']);
  }  

  exportExcel(filtrados: any) {
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.denominaciones;
    }

    for (let element of datos) {
      var obj = {
        'ID Denominación': element.idDenominacion,
        'ID gaveta a la que pertenece':element.idGaveta,
        'Tipo gaveta':element.tipoGaveta,
        'Denominación': element.denominacion1,
        'Tipo': element.tipo,
        'Cantidad': element.cantidad
        //'Estado': element.estado,
      }
      toExport.push(obj);
    }

    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(toExport);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Denominaciones");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

}
