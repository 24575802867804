<!-- <div id="mySidenav" class="sidenav">
    <a href="#" [routerLink]="['/denominacion']">Denominaciones</a>
    <hr>
    <a href="#" [routerLink]="['/dispositivo']">Dispositivos</a>
    <hr>
    <a href="#" [routerLink]="['/gaveta']">Gavetas</a>
    <hr>
    <a href="#" [routerLink]="['/sucursal']">Sucursales</a>
    <hr>
    <a href="#" [routerLink]="['/totem']">Tótems</a>
    <hr>
    <a href="#" [routerLink]="['/usuario']">Usuarios</a>
    <hr>
  </div>

  <span (click)="openNav()" class="btn btn-primary" id="boton">Abrir</span>
  <span (click)="closeNav()" class="btn btn-primary" id="boton2">Cerrar</span> -->

  <p-sidebar [(visible)]="display" position="left" *ngIf="Usuario">
    <h2>Menú</h2>
    <hr>
    <a  href="#" [routerLink]="['/denominacion']">Denominaciones</a>
    <hr>
    <a *ngIf="Usuario['idRol'] == 2 || Usuario['idRol'] == 1" href="#" [routerLink]="['/dispositivo']">Dispositivos</a>
    <hr>
    <a *ngIf="Usuario['idRol'] == 2 || Usuario['idRol'] == 1" href="#" [routerLink]="['/gaveta']">Gavetas</a>
    <hr>
    <a *ngIf="Usuario['idRol'] == 2 || Usuario['idRol'] == 1" href="#" [routerLink]="['/sucursal']">Sucursales</a>
    <hr>
    <a *ngIf="Usuario['idRol'] == 2 || Usuario['idRol'] == 1"  href="#" [routerLink]="['/totem']">Tótems</a>
    <hr>
    <a *ngIf="Usuario['idRol'] == 1 "  href="#" [routerLink]="['/usuario']">Usuarios</a>
    <hr>
</p-sidebar>

<p-button type="text" (click)="display = true" icon="pi pi-arrow-right"></p-button>