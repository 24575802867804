import { Component, OnInit, Inject } from '@angular/core';
import * as moment from 'moment';
import { Movimiento } from 'src/app/core/models/movimiento';
import { EfectivoService } from 'src/app/core/services/http/efectivo.service';
import { TotemService } from 'src/app/core/services/http/totem.service';
import { DenominacionService } from 'src/app/core/services/http/denominacion.service';
import { SucursalService } from 'src/app/core/services/http/sucursal.service';
//PDF
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
//
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { Number_0Module } from '@carbon/icons-angular';
import { Totem } from 'src/app/core/models/totem';

@Component({
  selector: 'app-movimiento',
  templateUrl: './movimiento.component.html',
  styleUrls: ['./movimiento.component.scss'],
})
export class MovimientoComponent implements OnInit {
  denominaciones: any[];
  //
  filtrado;
  moment: any = moment;
  movimientos: Movimiento[];
  cols: any[];
  exportColumns: any[];
  //FILTROS
  feInicio: string;
  feTermino: string;
  idTotem: number;
  idDenominacion;
  tipoMovimiento;
  fechaRegistro;
  TotalIngresor=0;
  totalTransbank=0;
  totemSelect;
  sucursalSelect;
  tipos: any[];
  oficinas: any[];
  totems: Totem[];
  TotemsAux: any[];
  oficinaSelect: string;
  constructor(
    private EfectivoService: EfectivoService,
    private TotemService: TotemService,
    private DenominacionService: DenominacionService,
    private SweetAlertService: SweetAlertService,
    private SucursalService: SucursalService
  ) {}

  async ngOnInit() {
    this.SweetAlertService.swalLoading();
    this.totemSelect = "";
    this.sucursalSelect = "";
    await this.TotemService.getTotems().then(totems => this.totems = totems);
    this.TotemsAux = this.totems;
    await this.SucursalService.getSucursales().then(oficinas => this.oficinas = oficinas['data']);
    this.feInicio = this.moment(new Date()).format('YYYY-MM-DD');
    this.feTermino = this.moment(new Date()).format('YYYY-MM-DD');
    this.SweetAlertService.swalClose();
    //COLUMNAS
    this.cols = [
      { field: 'idMovimiento', header: 'N° Movimiento' },
      { field: 'idCierre', header: 'ID Cierre' },
      { field: 'rutUsuario', header: 'Rut usuario' },
      { field: 'tipo', header: 'Tipo' },
      { field: 'montoIngreso', header: 'Monto ingresado' },
      { field: 'fechaRegistro', header: 'Fecha de registro' },
    ];

    //FILTROS DE TIPO DE MOVIMIENTO PARA TABLA
    this.tipos = [
      { label: 'Discrepancia', value: 'D' },
      { label: 'Egresado', value: 'E' },
      { label: 'Ingresado', value: 'I' },
      { label: 'Retiro', value: 'R' },
      { label: 'Carga', value: 'C' },
      { label: 'TransBank', value: 'T' }
    ];

    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  async filtrar() {
    this.filtrado = false;
    if (this.moment(this.feInicio).isAfter(this.moment(this.feTermino))) {
      this.SweetAlertService.swalError('Ingrese un rango de fechas valido');
    }
    else {
      this.filtrado = true;
      var filtros = {
        sucursal: this.sucursalSelect,
        idTotem: this.totemSelect,
        feInicio: this.feInicio,
        feTermino: this.feTermino
      };
      this.SweetAlertService.swalLoading();
      await this.EfectivoService.getMovimientos(filtros).then((movimientos) => (this.movimientos = movimientos['data']));
      this.SweetAlertService.swalClose();
    }
  }

  SucursalSeleccionada(Sucursal)    
  {
    this.TotemsAux = this.totems;
    if (Sucursal != -1) {
      this.TotemsAux = this.TotemsAux.filter(function (array) {
        if (array.idOficina == Sucursal) {
          return array
        }
      });
    }
  }

  RealizarSuma(DatosTabla) {
    this.TotalIngresor = 0;
    if (DatosTabla == undefined) {
      this.movimientos.forEach(element => {
        if (element.tipo == "I") {
          this.TotalIngresor += element.montoIngreso;
        }
        if (element.tipo == "E") {
          this.TotalIngresor -= element.montoIngreso;
        }
        if (element.tipo == "C") {
          this.TotalIngresor += element.montoIngreso;
        }
        if (element.tipo == "R") {
          this.TotalIngresor -= element.montoIngreso;
        }
      });
    }
    else{
      DatosTabla.forEach(element => {
        if (element.tipo == "I") {
          this.TotalIngresor += element.montoIngreso;
        }
        if (element.tipo == "E") {
          this.TotalIngresor -= element.montoIngreso;
        }
        if (element.tipo == "C") {
          this.TotalIngresor += element.montoIngreso;
        }
        if (element.tipo == "R") {
          this.TotalIngresor -= element.montoIngreso;
        }
      });
    }

    return this.TotalIngresor;
  }

  sumaTransbank(DatosTabla)
  {
    this.totalTransbank = 0;
    if (DatosTabla == undefined) {
      this.movimientos.forEach(element => {
        if (element.tipo == "T") {
          this.totalTransbank += element.montoIngreso;
        }
      });
    }
    return this.totalTransbank;
  }

  //EXPORTAR A EXCEL
  exportExcel(filtrados: any) {
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.movimientos;
    }

    for (let element of datos) {
      var obj = {
        'N° Movimiento': element.idMovimiento,
        'ID Totem': element.idTotem,
        'N° Cierre': element.idCierre,
        'Rut usuario': element.rutUsuario,
        Tipo: element.tipo,
        Monto: element.montoIngreso,
        'Fecha de registro': this.moment(element.fechaRegistro).format(
          'DD-MM-YYYY'
        ),
      };
      toExport.push(obj);
    }

    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(toExport);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, 'Movimientos');
    });
  }
  //CONVERTIR EN EXCEL
  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver) => {
      let EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }

  //exportar a PDF
  exportPdf(filtrados: any) {
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.movimientos;
    }
    for (let element of datos) {
      var obj = {
        idMovimiento: element.idMovimiento,
        idCierre: element.idCierre,
        rutUsuario: element.rutUsuario,
        tipo: element.tipo,
        montoIngreso: element.montoIngreso,
        fechaRegistro: this.moment(element.fechaRegistro).format('DD-MM-YYYY'),
      };
      toExport.push(obj);
    }
    var doc = new jsPDF('p', 'pt');
    doc.text('Movimientos', 50, 30);
    autoTable(doc, { columns: this.exportColumns, body: toExport });
    doc.save('Movimientos.pdf');
  }
}
