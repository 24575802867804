import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import {MessageService} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { SucursalService } from 'src/app/core/services/http/sucursal.service';
import { TotemService } from 'src/app/core/services/http/totem.service';
import { Totem } from 'src/app/core/models/totem'; 
import * as moment from 'moment';
import {SweetAlertService} from 'src/app/core/services/sweet-alert/sweet-alert.service';
@Component({
  selector: 'app-totem',
  templateUrl: './totem.component.html',
  styleUrls: ['./totem.component.scss']
})
export class TotemComponent implements OnInit {

  moment: any = moment;
  oficinas: any[];
  totems: Totem[];
  nombre: any[];
  totemDialog: boolean;
  totem: Totem;
  submitted: boolean;
  nombreTotem;
  nombreOficina;
  accion;
  titulo;
  constructor(
    private SucursalService: SucursalService,
    private ConfirmationService: ConfirmationService,
    private MessageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private TotemService: TotemService,
    private SweetAlertService: SweetAlertService) { }

  async ngOnInit() {
    this.primengConfig.ripple = true;
    this.accion = ""; 
    this.SweetAlertService.swalLoading();
    await this.TotemService.getTotems().then(totems => this.totems = totems);
    
    this.SweetAlertService.swalClose();
    
    //GAVETAS
    await this.SucursalService.getSucursales().then(oficinas => this.oficinas = oficinas['data']);
    //   var resp =await this.TotemService.getTotems();
    //  console.log("a",resp);
    
  }

  async openNew() {
    this.totem = {};
    this.totem.idOficina=0;
    this.submitted = false;
    this.totemDialog = true;
    this.accion = "Agregar";
    this.titulo = "Nuevo tótem";
  }

  editTotem(totem: Totem) {
    this.totem = { ...totem };
    this.totemDialog = true;
    this.accion = "Actualizar";
    this.titulo = "Modificar tótem";
  }

  async deleteTotem(totem: Totem) {
    
    this.ConfirmationService.confirm({
      message: 'Esta seguro(a) que desea eliminar el tótem N°' + totem.idTotem + " de la oficina N°" + totem.idOficina + '?',
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        var totemDeleted = {
          "acc": "D",
          "idTotem": totem.idTotem
        }
        var resp = await this.TotemService.eliminarTotem(totemDeleted);
        if (resp['status']) {
          this.totem = {};
          await this.TotemService.getTotems().then(totems => this.totems = totems);
          this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Tótem eliminado', life: 3000 });
        } else {
          this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'El tótem no se pudo eliminar', life: 3000 });
        }

      }
    });

  }

  hideDialog() {
    this.totemDialog = false;
    this.submitted = false;
  }

  async saveTotem() {

    var aux = 0;
    this.submitted = true;
    if (this.accion === "Agregar") {
      var crearTotem = {
        "acc": "N",
        "idOficina": this.totem.idOficina,
        "estado":true,
        "nombre":this.totem.nombre
      }
      if (this.totem.idOficina>0) {
        var resp = await this.TotemService.administrarTotem(crearTotem);
        this.totemDialog = false;
        if (resp['status']) {
          this.totem = {};
          this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Tótem agregado', life: 3000 });
        } else {
          this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'El tótem no se pudo agregar', life: 3000 });
        }

      }

    } else if (this.accion === "Actualizar") {
      var actualizarTotem = {
        "acc": "U",
        "idTotem":this.totem.idTotem,
        "idOficina": this.totem.idOficina,
        "estado":true,
        "nombre":this.totem.nombre
      }
      if (this.totem.idOficina>0) {
        var resp = await this.TotemService.administrarTotem(actualizarTotem);
        this.totemDialog = false;
        this.totem = {};
        if (resp['status']) {
          this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Tótem actualizado', life: 3000 });
        } else {

          this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'No se pudo actualizar los datos del tótem', life: 4000 });
        }
      }
    }
    //ACTUALIZAR LISTA DE SUCURSALES
    await this.TotemService.getTotems().then(totems => this.totems = totems);
  }

  exportExcel(filtrados: any) {
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.totems;
    }

    for (let element of datos) {
      var obj = {
        'ID Tótem': element.idTotem,
        'ID Oficina al que pertenece':element.idOficina,
        'Nombre': element.nombre
        //'Estado': element.estado,
      }
      toExport.push(obj);
    }

    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(toExport);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Tótems");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }


}
