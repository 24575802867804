import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/core/models/usuario';
import { UsuarioService } from 'src/app/core/services/http/usuario.service';
import { ConfirmationService } from 'primeng/api';
import {MessageService} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import {SweetAlertService} from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { ValidatorsService } from 'src/app/core/services/miscellaneous/validators.service';
import { SucursalService } from 'src/app/core/services/http/sucursal.service';
@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {
  usuarioDialog: boolean;
  usuarios: Usuario[];
  usuario: Usuario;
  selectedUsuarios: Usuario[];
  submitted: boolean;
  accion;
  titulo;
  rutValid;
  disabled;
  oficinaCheck: any[];
  oficinas: any[];
  oficinaSelect;
  sucursalSelect;
  filtrado;
  ofiSelect;
  constructor(
    private UsuarioService: UsuarioService,
    private ConfirmationService: ConfirmationService,
    private MessageService: MessageService, 
    private primengConfig: PrimeNGConfig,
    private SucursalService: SucursalService,
    private SweetAlertService: SweetAlertService,
    private ValidatorsService: ValidatorsService) { }

    async ngOnInit() {
      this.SweetAlertService.swalLoading();
      this.sucursalSelect = "";
      this.ofiSelect = "";
      await this.SucursalService.getSucursales().then(oficinas => this.oficinas = oficinas['data']);
      this.primengConfig.ripple = false;
      this.accion = ""; 
      this.filtrado = false
      this.SweetAlertService.swalClose();
    }

    async filtrar(){
      if(this.oficinaSelect == "")
      {
        await this.SweetAlertService.swalError("Debe seleccionar sucursal y totem");
      }
      else{
        this.SweetAlertService.swalLoading();
        this.filtrado = true;
        var filtros = {
          sucursal: this.sucursalSelect  
        };
        this.SweetAlertService.swalLoading();
        await this.UsuarioService.getUsuarios(filtros).then(usuarios => this.usuarios = usuarios['dataRol']);
        this.SweetAlertService.swalClose();
      }
    }

  validaRut(): void {
    this.rutValid = this.ValidatorsService.validarRut(this.usuario.rut.replace(/\./g, '').replace('-', ''));
    this.usuario.rut = this.ValidatorsService.formateaRut(this.usuario.rut);
    if (this.usuario.rut.length > 10 && this.rutValid) {
      this.usuario.rut=this.usuario.rut;
    }
  }

  async openNew() {
    this.usuario={};
    this.usuario.idRol=0;
    this.submitted = false;
    this.usuarioDialog = true;
    this.accion = "Agregar";
    this.titulo="Nuevo usuario";
  }

  editUsuario(usuario: Usuario) {
    this.usuario = { ...usuario };
    this.usuario.clave = "";
    this.usuarioDialog = true;
    this.accion = "Actualizar";
    this.titulo="Modificar usuario";
  }

  async deleteUsuario(usuario: Usuario) {
    this.ConfirmationService.confirm({
      message: 'Esta seguro(a) que desea eliminar a: ' + usuario.nombre + " "+ usuario.apellido +'?',
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        var usuarioDeleted = {
          "acc": "D",
          "rut": usuario.rut
        }
        console.log("Que envio: ",usuarioDeleted);
        
        var resp = await this.UsuarioService.deleteUsuario(usuarioDeleted);
        console.log("que recibo: ",resp);
        
        if(resp['status']){
          this.usuario= {};
          await this.UsuarioService.getUsuarios(usuario).then(usuarios => this.usuarios = usuarios['dataRol']);
          this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Usuario eliminado', life: 3000 });
        }else{
          this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'El usuario no se pudo eliminar', life: 3000 });
        }
        
      }
    });
    
  }

  hideDialog() {
    this.usuarioDialog = false;
    this.submitted = false;
  }

  async saveSucursal() {
    var aux=0;
    this.submitted = true;
    if (this.accion === "Agregar") {
      var crearSucursal = {
        "acc": "N",
        "rut": this.usuario.rut,
        "idRol": this.usuario.idRol,
        "nombre": this.usuario.nombre,
        "apellido": this.usuario.apellido,
        "clave": this.usuario.clave,
        "estado": true,
        "idOficina": this.sucursalSelect
      }
      console.log("DATOS QUE ENVÍA AL CREAR: ", crearSucursal);
      
      if(this.usuario.rut && this.usuario.idRol && this.usuario.nombre && this.usuario.apellido && this.usuario.clave && this.rutValid)
      {
        var resp = await this.UsuarioService.agregarUsuario(crearSucursal);
        console.log("Respuesta: ",resp);
        this.usuarioDialog = false;
        
      if (resp['status']) {
        this.usuario={};
        this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Usuario agregado', life: 3000 });
      }else{
        this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'El usuario no se pudo agregar', life: 3000 });
      }
      
      }
      
    } else if(this.accion==="Actualizar") {
      //ACTUALIZAR
      var actualizarUsuario = {
        "acc": "U",
        "rut": this.usuario.rut,
        "idRol": this.usuario.idRol,
        "nombre": this.usuario.nombre,
        "apellido": this.usuario.apellido,
        "clave": this.usuario.clave,
        "estado": true,
        "idOficina": 0
      }
      if(this.usuario.rut && this.usuario.idRol && this.usuario.nombre && this.usuario.apellido){
        var resp = await this.UsuarioService.agregarUsuario(actualizarUsuario);
        this.usuarioDialog = false;
        this.usuario={};
        if (resp['status']) {
          this.MessageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Usuario actualizado', life: 3000 });
        }else{
          
          this.MessageService.add({ severity: 'error', summary: 'Fallido', detail: 'No se pudo actualziar los datos del usuario', life: 4000 });
        }
      }
      
      
    }
    //ACTUALIZAR LISTA DE SUCURSALES
    var filtros = {
      sucursal: this.sucursalSelect  
    };
    await this.UsuarioService.getUsuarios(filtros).then(usuarios => this.usuarios = usuarios['dataRol']);
    
        
  }

  exportExcel(filtrados: any) {
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.usuarios;
    }

    for (let element of datos) {
      var obj = {
        'Rut': element.rut,
        'Nombre': element.nombre + " " + element.apellido,
        'Rol': element.nombreRol,
        //'Estado': element.estado,
      }
      toExport.push(obj);
    }

    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(toExport);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Usuarios");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
}
