<app-header></app-header>
<div id="main">
    <app-sidebar></app-sidebar>
    <div class="text-center">
        <img width="25%" src="./assets/img/totalpack.png">
    </div>
    <div class="offset-1 col-10">
        <div class="row">
            <div class="form-group col-5">
                <div class="col-sm-12" style="position: relative; left: 750px; width: 280px;">
                    <select [(ngModel)]="sucursalSelect" class="form-control">
                        <option value="">Seleccione Sucursal</option>
                        <option *ngFor="let sucursal of oficinas" [value]="sucursal.idOficina">{{sucursal.idOficina}} - {{sucursal.nombre}}</option>
                        <option value="-1">Todos</option>
                    </select>
                </div>
            </div>
            <button (click)="filtrar()" class="mr-5 btn btn-primary" style="position: relative; left: 300px; bottom: 16px;">Listar usuarios</button>
        </div>
        <p-toast></p-toast>
        <div class="card">
            <p-toolbar styleClass="p-mb-4" *ngIf="sucursalSelect != 0">
                <ng-template pTemplate="left">
                    <button pButton pRipple label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2"
                        (click)="openNew()"></button>
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton pRipple label="Exportar a excel" (click)="exportExcel(dt.filteredValue)"
                        icon="pi pi-upload" class="p-button-help"></button>
                </ng-template>
            </p-toolbar>
            <p-table #dt [value]="usuarios" [rows]="10" [paginator]="true" *ngIf="filtrado && usuarios && usuarios.length>=1"
                [globalFilterFields]="['rut','nombre', 'apellido', 'nombreRol']" [rowHover]="true" dataKey="usuario.rut"
                currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} encontrados"
                [showCurrentPageReport]="true">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <h5 class="p-m-0">Usuarios</h5>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Buscar..." />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="rut">Rut <p-sortIcon field="rut"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nombre">Nombre <p-sortIcon field="nombre"></p-sortIcon>
                        </th>
                        <th pSortableColumn="apellido">Apellido <p-sortIcon field="apellido"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nombreRol">Rol <p-sortIcon field="nombreRol"></p-sortIcon>
                        </th>
                        <!-- <th pSortableColumn="estado">Status <p-sortIcon field="estado"></p-sortIcon></th> -->
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-usuario>
                    <tr>
                        <td *ngIf="usuario.estado === true">{{usuario.rut}}</td>
                        <td *ngIf="usuario.estado === true">{{usuario.nombre}}</td>
                        <td *ngIf="usuario.estado === true">{{usuario.apellido}}</td>
                        <td *ngIf="usuario.estado === true">{{usuario.nombreRol}}</td>
                        <!-- <td>{{usuario.estado}}</td> -->
                        <td>
                            <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                (click)="editUsuario(usuario)"></button>
                            <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning"
                                (click)="deleteUsuario(usuario)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        Existen {{usuarios ? usuarios.length : 0 }} usuarios.
                    </div>
                </ng-template>
            </p-table>
        </div>
        <p-dialog [(visible)]="usuarioDialog" [style]="{width: '450px'}" [header]="titulo" [modal]="true"
            styleClass="p-fluid">
            <ng-template pTemplate="content">
                <div class="p-field">
                    <label for="rut">Rut</label>
                    <input type="text" [disabled]="accion==='Actualizar'" pInputText id="rut" [(ngModel)]="usuario.rut"
                        required autofocus class="form-control" maxlength="12"
                        [class.is-invalid]="usuario.rut && !rutValid" (keyup)="validaRut()" />
                    <small class="p-invalid" *ngIf="submitted && !usuario.rut">El rut es obligatorio</small>
                    <small class="p-invalid" *ngIf="submitted && !rutValid && usuario.rut">El rut ingresado no es
                        valido</small>
                </div>
                <div class="p-field">
                    <label for="nombre">Nombre</label>
                    <input type="text" pInputText id="nombre" [(ngModel)]="usuario.nombre" required autofocus
                        class="form-control" />
                    <small class="p-invalid" *ngIf="submitted && !usuario.nombre">El nombre del usuario es
                        requerido</small>
                </div>
                <div class="p-field">
                    <label for="apellido">Apellido</label>
                    <input type="text" pInputText id="apellido" [(ngModel)]="usuario.apellido" required autofocus
                        class="form-control" />
                    <small class="p-invalid" *ngIf="submitted && !usuario.apellido">El apellido del usuario es
                        requerido</small>
                </div>
                <div class="p-field">
                    <label for="idRol">Rol</label>
                    <select id="idRol" [(ngModel)]="usuario.idRol" required autofocus class="form-control">
                        <option value="1">Admin</option>
                        <option value="2">Admin Sucursal</option>
                        <option value="3">Usuario</option>
                    </select>
                </div>
                <div class="p-field">
                    <label for="clave">clave</label>
                    <input type="password" pInputText id="clave" [(ngModel)]="usuario.clave" placeholder="****" required
                        autofocus class="form-control" />
                    <small class="p-invalid" *ngIf="submitted && !usuario.clave && accion==='Agregar'">La contraseña del
                        usuario es requerido</small>
                </div>
                <br>
                <button class="btn btn-primary" (click)="saveSucursal()">{{accion}}</button>

            </ng-template>

        </p-dialog>
        <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    </div>
</div>