import { Component, OnInit, OnChanges } from '@angular/core';
import * as moment from 'moment';
import {Movimiento} from 'src/app/core/models/movimiento';
import { EfectivoService } from 'src/app/core/services/http/efectivo.service';
import {jsPDF} from 'jspdf';
import autoTable from 'jspdf-autotable'
import {SweetAlertService} from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { SucursalService } from 'src/app/core/services/http/sucursal.service';
import { TotemService } from 'src/app/core/services/http/totem.service';
import { Totem } from 'src/app/core/models/totem';

@Component({
  selector: 'app-cierre-x',
  templateUrl: './cierre-x.component.html',
  styleUrls: ['./cierre-x.component.scss']
})
export class CierreXComponent implements OnInit, OnChanges {

  cierre: Movimiento[];
  oficinaSelect;
  //
  filtrado;
  moment: any = moment;
  movimientos: Movimiento[];
  cols: any[];
  exportColumns: any[];
  //FILTROS
  feInicio: string;
  feTermino: string;
  cierreConTotal: any;
  tipos: any[];
  TotalIngresor: number;
  totems: Totem[];
  TotemsAux: any[];
  oficinas: any[];
  totemSelect;
  sucursalSelect;
  isCierreX;
  mensaje: any;
  constructor(
    private EfectivoService: EfectivoService, 
    private SweetAlertService: SweetAlertService,
    private SucursalService: SucursalService,
    private TotemService: TotemService) { }

  async ngOnInit() {
    this.SweetAlertService.swalLoading();
    this.totemSelect = "";
    this.sucursalSelect = "";
    this.cierre = [];
    await this.TotemService.getTotems().then(totems => this.totems = totems);
    this.TotemsAux = this.totems;
    await this.SucursalService.getSucursales().then(oficinas => this.oficinas = oficinas['data']);
    this.feInicio = this.moment(new Date()).format('YYYY-MM-DD');
    this.feTermino = this.moment(new Date()).format('YYYY-MM-DD');
    //FILTROS TIPO
    this.tipos=[
      {label: 'Egresado', value:'E'},
      {label: 'Ingresado', value:'I'},
      {label: 'Discrepancia', value:'D'},
      {label: 'Carga', value:'C'},
      {label: 'Retiro', value:'R'}
    ]
    this.filtrado = false
    //OBTENER DENOMINACIONES
    // this.SweetAlertService.swalLoading();
    // this.cierre = await this.EfectivoService.getCierreZ().then(cierre => this.cierre = cierre['data']);
    // this.SweetAlertService.swalClose();
    //COLUMNAS
    this.cols = [
      { field: 'idMovimiento', header: 'N° Movimiento' },
      { field: 'idDenominacion', header: 'N° Denominación' },
      { field: 'rutUsuario', header: 'Rut usuario' },
      { field: 'tipo', header: 'Tipo' },
      { field: 'montoIngreso', header: 'Monto ingresado' },
      { field: 'fechaRegistro', header: 'Fecha de registro' }
  ];

  this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  this.SweetAlertService.swalClose();
  }

  SucursalSeleccionada(Sucursal)    
  {
      this.TotemsAux = this.totems;
      if (Sucursal != -1) {
        this.TotemsAux = this.TotemsAux.filter(function (array) {
          if (array.idOficina == Sucursal) {
            return array
          }
        });
      }
  }

  ngOnChanges(){
    console.log("on change");
    
  }

  async filtrar(){
    if(this.sucursalSelect == "" || this.totemSelect == "")
    {
      this.filtrado = false;
      await this.SweetAlertService.swalError("Debe seleccionar sucursal y totem");
      this.cierre = []
    }
    else{
      this.cierre = [];
      this.filtrado = true;
      this.SweetAlertService.swalLoading();
      await this.EfectivoService.getCierreX(parseInt(this.totemSelect), this.sucursalSelect).then(cierre => this.cierre = cierre['data']);
      if(this.cierre == null)
      {
        this.cierre = []
      }
      this.SweetAlertService.swalClose();
    }
  }

  RealizarSuma(DatosTabla) {
    this.TotalIngresor = 0;
    if (DatosTabla == undefined) {
      this.cierre.forEach(element => {
        if (element.tipo == "I") {
          this.TotalIngresor += element.montoIngreso;
        }
        if (element.tipo == "E") {
          this.TotalIngresor -= element.montoIngreso;
        }
      });
    }
    else{
      DatosTabla.forEach(element => {
        if (element.tipo == "I") {
          this.TotalIngresor += element.montoIngreso;
        }
        if (element.tipo == "E") {
          this.TotalIngresor -= element.montoIngreso;
        }
      });
    }

    return this.TotalIngresor;
  }

  tipoCierre(cierre: any){
    if(cierre==='cierreX'){
      this.isCierreX=true;
    }
  }

  //EXPORTAR A EXCEL
    exportExcel(filtrados: any) {
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.cierre;
    }

    for (let element of datos) {
      var tipo;
      if(element.tipo==="D"){
        tipo="Discrepancia"
      }else if(element.tipo=="I"){
        tipo="Ingresado"
      }else{
        tipo="Egresado"
      }

      var obj = {
        'N° Movimiento': element.idMovimiento,
        'N° Denominación':element.idDenominacion,
        'N° Cierre': element.idCierre,
        'Rut usuario': element.rutUsuario,
        'Tipo': tipo,
        'Monto': element.montoIngreso,
        'Fecha de registro': this.moment(element.fechaRegistro).format('DD-MM-YYYY')
      }
      toExport.push(obj);
    }

      import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(toExport);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, "CierreX");
      });
  }
  //CONVERTIR EN EXCEL
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  //exportar a PDF
  exportPdf(filtrados: any) {
    
    var datos = [];
    var toExport = [];
    if (filtrados != null && filtrados.length > 0) {
      datos = filtrados;
    } else {
      datos = this.cierre;
    }
    for (let element of datos) {
      var tipo;
      if(element.tipo==="D"){
        tipo="Discrepancia"
      }else if(element.tipo=="I"){
        tipo="Ingresado"
      }else{
        tipo="Egresado"
      }
      var obj = {
        'idMovimiento': element.idMovimiento,
        'idDenominacion':element.idDenominacion,
        'idCierre': element.idCierre,
        'rutUsuario': element.rutUsuario,
        'tipo': tipo,
        'montoIngreso': element.montoIngreso,
        'fechaRegistro': this.moment(element.fechaRegistro).format('DD-MM-YYYY')
      }
      toExport.push(obj);
    }
     var doc = new jsPDF('p', 'pt');
     doc.text("Cierre X",50, 30);
      autoTable(doc, {columns: this.exportColumns,body: toExport})
      doc.save('CierreX.pdf');
  }

}
